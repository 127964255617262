import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import routePaths from "./routePaths";
import Regions from "../../pages/serviceOrganizationOperator/Addresses/Regions";
import RegionCreate from "../../pages/serviceOrganizationOperator/Addresses/Regions/RegionCreate";
import RegionEdit from "../../pages/serviceOrganizationOperator/Addresses/Regions/RegionEdit";
import Cities from "../../pages/serviceOrganizationOperator/Addresses/Cities";
import CityCreate from "../../pages/serviceOrganizationOperator/Addresses/Cities/CityCreate";
import CityEdit from "../../pages/serviceOrganizationOperator/Addresses/Cities/CityEdit";
import Streets from "../../pages/serviceOrganizationOperator/Addresses/Streets";
import StreetCreate from "../../pages/serviceOrganizationOperator/Addresses/Streets/StreetCreate";
import StreetEdit from "../../pages/serviceOrganizationOperator/Addresses/Streets/StreetEdit";
import Houses from "../../pages/serviceOrganizationOperator/Addresses/Houses";
import HouseCreate from "../../pages/serviceOrganizationOperator/Addresses/Houses/HouseCreate";
import HouseEdit from "../../pages/serviceOrganizationOperator/Addresses/Houses/HouseEdit";
import Apartments from "../../pages/serviceOrganizationOperator/Addresses/Apartments";
import ApartmentCreate from "../../pages/serviceOrganizationOperator/Addresses/Apartments/ApartmentCreate";
import ApartmentEdit from "../../pages/serviceOrganizationOperator/Addresses/Apartments/ApartmentEdit";
import Bankbooks from "../../pages/serviceOrganizationOperator/Bankbooks";
import BankbookCreate from "../../pages/serviceOrganizationOperator/Bankbooks/BankbookCreate";
import BankbookEdit from "../../pages/serviceOrganizationOperator/Bankbooks/BankbookEdit";
import Devices from "../../pages/serviceOrganizationOperator/Devices";
import DeviceCreate from "../../pages/serviceOrganizationOperator/Devices/DeviceCreate";
import DeviceEdit from "../../pages/serviceOrganizationOperator/Devices/DeviceEdit";
import {useTranslation} from "react-i18next";
import DefaultLayout from "../../layouts/DefaultLayout";
import Districts from "../../pages/serviceOrganizationOperator/Addresses/Districts";
import DistrictCreate from "../../pages/serviceOrganizationOperator/Addresses/Districts/DistrictCreate";
import DistrictEdit from "../../pages/serviceOrganizationOperator/Addresses/Districts/DistrictEdit";
import Microdistricts from "../../pages/serviceOrganizationOperator/Addresses/Microdistricts";
import MicrodistrictCreate from "../../pages/serviceOrganizationOperator/Addresses/Microdistricts/MicrodistrictCreate";
import MicrodistrictEdit from "../../pages/serviceOrganizationOperator/Addresses/Microdistricts/MicrodistrictEdit";
import TasksPrimaryFilling from '../../pages/serviceOrganizationOperator/Tasks/TasksPrimaryFilling';
import TasksPrimaryFillingEdit from '../../pages/serviceOrganizationOperator/Tasks/TasksPrimaryFilling/TasksPrimaryFillingEdit';
import FillingTasks from '../../pages/serviceOrganizationOperator/Tasks/FillingTasks';
import FillingTasksEdit from '../../pages/serviceOrganizationOperator/Tasks/FillingTasks/FillingTasksEdit';
import TasksUnsealing from '../../pages/serviceOrganizationOperator/Tasks/TasksUnsealing';
import TasksUnsealingEdit from '../../pages/serviceOrganizationOperator/Tasks/TasksUnsealing/TasksUnsealingEdit';
import AddressesImport from '../../pages/serviceOrganizationOperator/Import/Addresses';
import DevicesImport from '../../pages/serviceOrganizationOperator/Import/Devices';
import generalRoutePaths from "../general/routePaths";
import Profile from '../../pages/general/Profile';
import { useAuth } from '../Auth/ProvideAuth';
import { useLangContext } from '../../i18n/ProvideLang';
import CompanyEdit from '../../pages/serviceOrganizationOperator/Companies/CompanyEdit';
import ServiceOrganizationsSealers from '../../pages/serviceOrganizationOperator/Users/ServiceOrganizationsSealers';
import ServiceOrganizationsSealersCreate from '../../pages/serviceOrganizationOperator/Users/ServiceOrganizationsSealers/ServiceOrganizationsSealersCreate';
import ServiceOrganizationsSealersEdit from '../../pages/serviceOrganizationOperator/Users/ServiceOrganizationsSealers/ServiceOrganizationsSealersEdit';
import ServiceOrganizationsOperators from '../../pages/serviceOrganizationOperator/Users/ServiceOrganizationsOperators';
import ServiceOrganizationsOperatorsCreate from '../../pages/serviceOrganizationOperator/Users/ServiceOrganizationsOperators/ServiceOrganizationsOperatorsCreate';
import ServiceOrganizationsOperatorsEdit from '../../pages/serviceOrganizationOperator/Users/ServiceOrganizationsOperators/ServiceOrganizationsOperatorsEdit';

const Routes = () => {
    const {t} = useTranslation();

    const {userData} = useAuth()
    const {lang} = useLangContext()

    const sidebarItems = [
        {
            title: userData?.serviceOrganization[`name_${lang}`],
            url: routePaths.company.index,
            icon: "briefcase"
        },
        {
            title: t('sidebar.users.title'),
            url: routePaths.users.index,
            icon: "users",
            children: [
                {
                    title: t('sidebar.users.children.service_organizations_operators'),
                    url: routePaths.users.children.service_organizations_operators.index
                },
                {
                    title: t('sidebar.users.children.service_organizations_sealers'),
                    url: routePaths.users.children.service_organizations_sealers.index
                },
            ]
        },
        {
            title: t('sidebar.addresses.title'),
            url: routePaths.addresses.index,
            icon: "marker",
            children: [
                {
                    title: t('sidebar.addresses.children.regions'),
                    url: routePaths.addresses.children.regions.index
                },
                {
                    title: t('sidebar.addresses.children.cities'),
                    url: routePaths.addresses.children.cities.index
                },
                {
                    title: t('sidebar.addresses.children.districts'),
                    url: routePaths.addresses.children.districts.index
                },
                {
                    title: t('sidebar.addresses.children.microdistricts'),
                    url: routePaths.addresses.children.microdistricts.index
                },
                {
                    title: t('sidebar.addresses.children.streets'),
                    url: routePaths.addresses.children.streets.index
                },
                {
                    title: t('sidebar.addresses.children.houses'),
                    url: routePaths.addresses.children.houses.index
                },
                {
                    title: t('sidebar.addresses.children.apartments'),
                    url: routePaths.addresses.children.apartments.index
                }
            ]
        },
        {
            title: t('sidebar.bankbooks.title'),
            url: routePaths.bankbooks.index,
            icon: "badge"
        },
        {
            title: t('sidebar.devices.title'),
            url: routePaths.devices.index,
            icon: "dashboard"
        },
        {
            title: t('sidebar.tasks_service_organizations.index'),
            url: routePaths.tasks.index,
            icon: "tasks",
            children: [
                {
                    title: t('sidebar.tasks.children.tasks_primary_filling.index'),
                    url: routePaths.tasks.children.tasks_primary_filling.index,
                    children: [
                        {
                            title: t('sidebar.tasks.children.tasks_primary_filling.children.in_work'),
                            url: routePaths.tasks.children.tasks_primary_filling.children.in_work.index
                        },
                        {
                            title: t('sidebar.tasks.children.tasks_primary_filling.children.on_check'),
                            url: routePaths.tasks.children.tasks_primary_filling.children.on_check.index
                        },
                        {
                            title: t('sidebar.tasks.children.tasks_primary_filling.children.done'),
                            url: routePaths.tasks.children.tasks_primary_filling.children.done.index
                        },
                        {
                            title: t('sidebar.tasks.children.tasks_primary_filling.children.declined'),
                            url: routePaths.tasks.children.tasks_primary_filling.children.declined.index
                        },
                        {
                            title: t('sidebar.tasks.children.tasks_primary_filling.children.all'),
                            url: routePaths.tasks.children.tasks_primary_filling.children.all.index
                        },
                    ]
                },
                {
                    title: t('sidebar.tasks.children.tasks_for_unsealing.index'),
                    url: routePaths.tasks.children.tasks_for_unsealing.index,
                    children: [
                        {
                            title: t('sidebar.tasks.children.tasks_for_unsealing.children.in_work'),
                            url: routePaths.tasks.children.tasks_for_unsealing.children.in_work.index
                        },
                        {
                            title: t('sidebar.tasks.children.tasks_for_unsealing.children.on_check'),
                            url: routePaths.tasks.children.tasks_for_unsealing.children.on_check.index
                        },
                        {
                            title: t('sidebar.tasks.children.tasks_for_unsealing.children.done'),
                            url: routePaths.tasks.children.tasks_for_unsealing.children.done.index
                        },
                        {
                            title: t('sidebar.tasks.children.tasks_for_unsealing.children.declined'),
                            url: routePaths.tasks.children.tasks_for_unsealing.children.declined.index
                        },
                        {
                            title: t('sidebar.tasks.children.tasks_for_unsealing.children.all'),
                            url: routePaths.tasks.children.tasks_for_unsealing.children.all.index
                        },
                    ]
                },
                {
                    title: t('sidebar.tasks.children.filling_tasks.index'),
                    url: routePaths.tasks.children.filling_tasks.index,
                    children: [
                        {
                            title: t('sidebar.tasks.children.filling_tasks.children.in_work'),
                            url: routePaths.tasks.children.filling_tasks.children.in_work.index
                        },
                        {
                            title: t('sidebar.tasks.children.filling_tasks.children.on_check'),
                            url: routePaths.tasks.children.filling_tasks.children.on_check.index
                        },
                        {
                            title: t('sidebar.tasks.children.filling_tasks.children.done'),
                            url: routePaths.tasks.children.filling_tasks.children.done.index
                        },
                        {
                            title: t('sidebar.tasks.children.filling_tasks.children.declined'),
                            url: routePaths.tasks.children.filling_tasks.children.declined.index
                        },
                        {
                            title: t('sidebar.tasks.children.filling_tasks.children.all'),
                            url: routePaths.tasks.children.filling_tasks.children.all.index
                        },
                    ]
                },
            ]
        },
    ]

    return (
        <DefaultLayout sidebarItems={sidebarItems}>
            <Switch>
                <Redirect exact from={routePaths.home.index} to={routePaths.addresses.children.regions.index}/>
                {/*Profile*/}
                <Route exact path={generalRoutePaths.profile.index} component={Profile}/>
                {/*Companies*/}
                <Route path={routePaths.company.index} component={CompanyEdit}/>
                {/*Users - Service Organizations Operators*/}
                <Route exact path={routePaths.users.children.service_organizations_operators.index} component={ServiceOrganizationsOperators}/>
                <Route exact path={routePaths.users.children.service_organizations_operators.create} component={ServiceOrganizationsOperatorsCreate}/>
                <Route exact path={routePaths.users.children.service_organizations_operators.edit()} component={ServiceOrganizationsOperatorsEdit}/>
                {/*Users - Service Organizations Operators*/}
                <Route exact path={routePaths.users.children.service_organizations_sealers.index} component={ServiceOrganizationsSealers}/>
                <Route exact path={routePaths.users.children.service_organizations_sealers.create} component={ServiceOrganizationsSealersCreate}/>
                <Route exact path={routePaths.users.children.service_organizations_sealers.edit()} component={ServiceOrganizationsSealersEdit}/>
                {/*Addresses*/}
                <Redirect exact from={routePaths.addresses.index} to={routePaths.addresses.children.regions.index}/>
                {/*Addresses - Regions*/}
                <Route exact path={routePaths.addresses.children.regions.index} component={Regions}/>
                <Route exact path={routePaths.addresses.children.regions.create} component={RegionCreate}/>
                <Route path={routePaths.addresses.children.regions.edit.index()} component={RegionEdit}/>
                {/*Addresses - Cities*/}
                <Route exact path={routePaths.addresses.children.cities.index} component={Cities}/>
                <Route exact path={routePaths.addresses.children.cities.create} component={CityCreate}/>
                <Route path={routePaths.addresses.children.cities.edit.index()} component={CityEdit}/>
                {/*Addresses - Districts*/}
                <Route exact path={routePaths.addresses.children.districts.index} component={Districts}/>
                <Route exact path={routePaths.addresses.children.districts.create} component={DistrictCreate}/>
                <Route path={routePaths.addresses.children.districts.edit.index()} component={DistrictEdit}/>
                {/*Addresses - Microdistricts*/}
                <Route exact path={routePaths.addresses.children.microdistricts.index} component={Microdistricts}/>
                <Route exact path={routePaths.addresses.children.microdistricts.create} component={MicrodistrictCreate}/>
                <Route path={routePaths.addresses.children.microdistricts.edit.index()} component={MicrodistrictEdit}/>
                {/*Addresses - Streets*/}
                <Route exact path={routePaths.addresses.children.streets.index} component={Streets}/>
                <Route exact path={routePaths.addresses.children.streets.create} component={StreetCreate}/>
                <Route path={routePaths.addresses.children.streets.edit.index()} component={StreetEdit}/>
                {/*Addresses - Houses*/}
                <Route exact path={routePaths.addresses.children.houses.index} component={Houses}/>
                <Route exact path={routePaths.addresses.children.houses.create} component={HouseCreate}/>
                <Route path={routePaths.addresses.children.houses.edit.index()} component={HouseEdit}/>
                {/*Addresses - Apartments*/}
                <Route exact path={routePaths.addresses.children.apartments.index} component={Apartments}/>
                <Route exact path={routePaths.addresses.children.apartments.create} component={ApartmentCreate}/>
                <Route path={routePaths.addresses.children.apartments.edit.index()} component={ApartmentEdit}/>
                {/*Bankbooks*/}
                <Route exact path={routePaths.bankbooks.index} component={Bankbooks}/>
                <Route exact path={routePaths.bankbooks.create} component={BankbookCreate}/>
                <Route path={routePaths.bankbooks.edit.index()} component={BankbookEdit}/>
                {/*Devices*/}
                <Route exact path={routePaths.devices.index} component={Devices}/>
                <Route exact path={routePaths.devices.create} component={DeviceCreate}/>
                <Route path={routePaths.devices.edit.index()} component={DeviceEdit}/>   

                {/*Tasks Rrimary Filling */}
                <Route exact path={routePaths.tasks.children.tasks_primary_filling.children.all.index}>
                    <TasksPrimaryFilling statusText={t('sidebar.tasks.children.tasks_primary_filling.children.all')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.tasks_primary_filling.children.on_check.index}>
                    <TasksPrimaryFilling key={routePaths.tasks.children.tasks_primary_filling.children.on_check.index} statusId="6"
                                 statusText={t('sidebar.tasks.children.tasks_primary_filling.children.on_check')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.tasks_primary_filling.children.done.index}>
                    <TasksPrimaryFilling key={routePaths.tasks.children.tasks_primary_filling.children.done.index} statusId="7"
                                 statusText={t('sidebar.tasks.children.tasks_primary_filling.children.done')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.tasks_primary_filling.children.declined.index}>
                    <TasksPrimaryFilling key={routePaths.tasks.children.tasks_primary_filling.children.declined.index} statusId="8"
                                 statusText={t('sidebar.tasks.children.tasks_primary_filling.children.declined')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.tasks_primary_filling.children.in_work.index}>
                    <TasksPrimaryFilling key={routePaths.tasks.children.tasks_primary_filling.children.in_work.index} statusId="5"
                                 statusText={t('sidebar.tasks.children.tasks_primary_filling.children.in_work')}/>
                </Route>
                <Route path={routePaths.tasks.children.tasks_primary_filling.edit()} component={TasksPrimaryFillingEdit}/>

                {/*Tasks Filling */}
                <Route exact path={routePaths.tasks.children.filling_tasks.children.all.index}>
                    <FillingTasks statusText={t('sidebar.tasks.children.filling_tasks.children.all')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.filling_tasks.children.on_check.index}>
                    <FillingTasks key={routePaths.tasks.children.filling_tasks.children.on_check.index} statusId="6"
                                 statusText={t('sidebar.tasks.children.filling_tasks.children.on_check')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.filling_tasks.children.done.index}>
                    <FillingTasks key={routePaths.tasks.children.filling_tasks.children.done.index} statusId="7"
                                 statusText={t('sidebar.tasks.children.filling_tasks.children.done')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.filling_tasks.children.declined.index}>
                    <FillingTasks key={routePaths.tasks.children.filling_tasks.children.declined.index} statusId="8"
                                 statusText={t('sidebar.tasks.children.filling_tasks.children.declined')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.filling_tasks.children.in_work.index}>
                    <FillingTasks key={routePaths.tasks.children.filling_tasks.children.in_work.index} statusId="5"
                                 statusText={t('sidebar.tasks.children.filling_tasks.children.in_work')}/>
                </Route>
                <Route path={routePaths.tasks.children.filling_tasks.edit()} component={FillingTasksEdit}/>

                {/*Tasks unsealing */}
                <Route exact path={routePaths.tasks.children.tasks_for_unsealing.children.all.index}>
                    <TasksUnsealing statusText={t('sidebar.tasks.children.tasks_for_unsealing.children.all')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.tasks_for_unsealing.children.on_check.index}>
                    <TasksUnsealing key={routePaths.tasks.children.tasks_for_unsealing.children.on_check.index} statusId="6"
                                 statusText={t('sidebar.tasks.children.tasks_for_unsealing.children.on_check')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.tasks_for_unsealing.children.done.index}>
                    <TasksUnsealing key={routePaths.tasks.children.tasks_for_unsealing.children.done.index} statusId="7"
                                 statusText={t('sidebar.tasks.children.tasks_for_unsealing.children.done')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.tasks_for_unsealing.children.declined.index}>
                    <TasksUnsealing key={routePaths.tasks.children.tasks_for_unsealing.children.declined.index} statusId="8"
                                 statusText={t('sidebar.tasks.children.tasks_for_unsealing.children.declined')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.tasks_for_unsealing.children.in_work.index}>
                    <TasksUnsealing key={routePaths.tasks.children.tasks_for_unsealing.children.in_work.index} statusId="5"
                                 statusText={t('sidebar.tasks.children.tasks_for_unsealing.children.in_work')}/>
                </Route>
                <Route path={routePaths.tasks.children.tasks_for_unsealing.edit()} component={TasksUnsealingEdit}/>
                {/*Imports*/}
                <Redirect exact from={routePaths.data_import.index} to={routePaths.data_import.children.addresses.index}/>
                <Route exact path={routePaths.data_import.children.addresses.index} component={AddressesImport}/>
                <Route exact path={routePaths.data_import.children.devices.index} component={DevicesImport}/>
            </Switch>
        </DefaultLayout>
    );
};

export default Routes;