import React, {useCallback, useEffect, useState} from 'react';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import ModalComponent from "../../../../components/ModalComponent";
import FormGroup from "../../../../components/FormGroup";
import Button from "../../../../components/Button";
import {Controller, useForm} from "react-hook-form";
import CustomSelect2 from "../../../../components/CustomSelect2";
import Dictionaries from '../../../../services/serviceOrganizationOperator/dictionaries';
import CustomSelect from '../../../../components/CustomSelect';

const BankBooksServiceTaskCreateModal = ({method, closeModal, successCallback, id, info, isSealing, ...props}) => {
    const [loading, setLoading] = useState();

    const {t} = useTranslation();

    const {handleSubmit, control, watch, setValue, reset} = useForm();

    const [devices, setDevices] = useState();
    const [devicesLoading, setDevicesLoading] = useState(true);

    const [sealers, setSealers] = useState();
    const [sealersLoading, setSealersLoading] = useState(true);
    
    const companyId = watch('company_id', null);

    const [companies, setCompanies] = useState();
    const [companiesLoading, setCompaniesLoading] = useState(true);

    const fetchDevices = useCallback(async () => {
        setDevicesLoading(true);

        let newOptions = info?.devices.map(item => { return {label: item.number, value: item.id} })
        
        setDevices(newOptions);

        setDevicesLoading(false);
    }, [setValue, info]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setValue('bankbook_id', id);
        fetchDevices();
    }, [fetchDevices, info]);// eslint-disable-line react-hooks/exhaustive-deps

    const setBankbook = () => {
        reset();
        setValue('bankbook_id', id);
    }
    
    const fetchCompanies = useCallback(async () => {
        setCompaniesLoading(true);

        const res = await Dictionaries.serviceOrganizations();

        if (res.statusCode === 200) {
            setCompanies(res.content);
        } else {
             toast.error(res.message)
        }

        setCompaniesLoading(false);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchSealers = useCallback(async () => {
        setSealers(null)

        if (info?.company.id) {
            setSealersLoading(true);

            const res = await Dictionaries.users.serviceOrganizationSealers({queryString: `service_organization_id=${companyId}`});

            if (res.statusCode === 200) {
                setSealers(res.content);
            } else {
                toast.error(res.message)
            }

            setSealersLoading(false);
        }
    }, [setValue, companyId]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchSealers();
    }, [fetchSealers]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);   

    const onSubmit = async (data) => {
        setLoading(true);

        setTimeout(async () => {
            const res = await method(data);

            res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

            if (res.statusCode === 200) {
                setBankbook();
                successCallback();
            }
            if(res.statusCode === 400){
                setBankbook();
            }

            setLoading(false);
        }, 500)
    };


    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{width: "400px", maxWidth: "100%"}}>
                <h4 className="headline-4"> {isSealing ? t('labels.tasks_filling') : t('labels.tasks_for_unsealing')}</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup label={t('labels.service_organization')}>
                        <Controller name="company_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={companies} onChange={onChange}
                                                      value={value}
                                                      isLoading={companiesLoading}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.devices')}>
                        <Controller name="devices" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect2 isMulti $small options={devices} onChange={onChange}
                                                        value={value}
                                                        isLoading={devicesLoading}
                                                        isDisabled={devicesLoading}
                                            />
                                    )}
                        />
                    </FormGroup>
                    <FormGroup label={t('labels.sealers')}>
                        <Controller name="users" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect2 isMulti $small options={sealers} onChange={onChange}
                                                        value={value}
                                                        isLoading={sealersLoading}
                                                        isDisabled={sealersLoading}
                                            />
                                    )}
                        />
                    </FormGroup>
                    <div className={`row row--multiline justify-end`}>
                        <div className="col-auto">
                            <Button >{t('labels.task_form')}</Button>
                        </div>
                    </div>
                </form>
            </div>
        </ModalComponent>
    );
};

export default BankBooksServiceTaskCreateModal;