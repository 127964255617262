import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import ModalComponent from "../../../../components/ModalComponent";
import Button from "../../../../components/Button";

const BankbooksActionsModal = ({method, method2, closeModal, successCallback, id, setIsSealing, ...props}) => {
    const [loading] = useState();

    const {t} = useTranslation();   

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{width: "400px", maxWidth: "100%"}}>
                <h4 className="headline-4">{t('labels.actions')}</h4>
                <Button onClick={()=>{method(); setIsSealing(true)}} $fluid className='mb-20'>{t('labels.sealers_task_create')}</Button>
                <Button onClick={()=>{method(); setIsSealing(false)}} $fluid className='mb-20'>{t('labels.unsealers_task_create')}</Button>
                <Button onClick={()=>{method2(); setIsSealing(true)}} $fluid className='mb-20'>{t('labels.sealers_service_task_create')}</Button>
                <Button onClick={()=>{method2(); setIsSealing(false)}} $fluid>{t('labels.unsealers_service_task_create')}</Button>
            </div>
        </ModalComponent>
    );
};

export default BankbooksActionsModal;