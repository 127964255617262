import React, {useCallback, useEffect, useState} from 'react';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import ModalComponent from "../../../../../components/ModalComponent";
import FormGroup from "../../../../../components/FormGroup";
import Button from "../../../../../components/Button";
import {Controller, useForm} from "react-hook-form";
import Dictionaries from '../../../../../services/admin/dictionaries';
import CustomSelect2 from "../../../../../components/CustomSelect2";
import CustomSelect from '../../../../../components/CustomSelect';

const ApartmentsTaskCreateModal = ({method, closeModal, successCallback, id, info, ...props}) => {
    const [loading, setLoading] = useState();

    const {t} = useTranslation();

    const {handleSubmit, control, watch, setValue, reset} = useForm();

    const companyId = watch('company_id', null);

    const [companies, setCompanies] = useState();
    const [companiesLoading, setCompaniesLoading] = useState(true);

    const [sealers, setSealers] = useState();
    const [sealersLoading, setSealersLoading] = useState(true);
    
    const fetchCompanies = useCallback(async () => {
        setCompaniesLoading(true);

        const res = await Dictionaries.companies();

        if (res.statusCode === 200) {
            setCompanies(res.content);
        } else {
             toast.error(res.message)
        }

        setCompaniesLoading(false);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchSealers = useCallback(async () => {
        setSealers(null)

        if (companyId) {
            setSealersLoading(true);

            const res = await Dictionaries.users.sealers({queryString: `company_id=${companyId}`});

            if (res.statusCode === 200) {
                setSealers(res.content);
            } else {
                toast.error(res.message)
            }

            setSealersLoading(false);
        }
        
    }, [setValue, companyId]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    useEffect(() => {
        fetchSealers();
        setValue('address_id', id);
    }, [fetchSealers]);// eslint-disable-line react-hooks/exhaustive-deps

    const setTask = () => {
        reset();
        setValue('address_id', id);
    }

    const onSubmit = async (data) => { 
        setLoading(true);

        setTimeout(async () => {
            const res = await method(data);

            res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

            if (res.statusCode === 200) {
                setTask();
                successCallback();
            }

            setLoading(false);
        }, 500)
    };

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{width: "400px", maxWidth: "100%"}}>
                <h4 className="headline-4">{t('labels.formation_task_primary')}</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup label={t('labels.resource_company')}>
                        <Controller name="company_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={companies} onChange={onChange}
                                                      value={value}
                                                      isLoading={companiesLoading}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.sealers')}>
                        <Controller name="users" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect2 isMulti $small options={sealers} onChange={onChange}
                                                        value={value}
                                                        isLoading={!!companyId ? sealersLoading : false}
                                                        isDisabled={!!!companyId || sealersLoading}
                                            />
                                    )}
                        />
                    </FormGroup>
                    <div className={`row row--multiline justify-end`}>
                        <div className="col-auto">
                            <Button >{t('labels.task_form')}</Button>
                        </div>
                    </div>
                </form>
            </div>
        </ModalComponent>
    );
};

export default ApartmentsTaskCreateModal;