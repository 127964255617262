import http from "./http-common"
import {getData, postData, deleteData} from "../handlers";

export default class TaskSealingServices {
    static firstTime = {
        index: (queryString) => {
            queryString = queryString.replace('status', 'status_id');
            return getData(http, `/tasks/serviceOrganizations/ftSealing${queryString ? `?${queryString}` : ""}`);
        },
        view: (id) => {
            return getData(http, `/tasks/serviceOrganizations/ftSealing/${id}`)
        },
        accept: (id) => {
            return postData(http, `/tasks/serviceOrganizations/ftSealing/${id}/accept`)
        },
        decline: (id, data) => {
            return postData(http, `/tasks/serviceOrganizations/ftSealing/${id}/decline`, data)
        },
        delete: (id) => {
            return deleteData(http, `/tasks/serviceOrganizations/ftSealing/${id}`)
        },
        store: (data) => {
            return postData(http, `/tasks/serviceOrganizations/ftSealing/store`, data)
        }
    }

    static unsealing = {
        index: (queryString) => {
            queryString = queryString.replace('status', 'status_id');
            return getData(http, `/tasks/serviceOrganizations/unsealing${queryString ? `?${queryString}` : ""}`);
        },
        view: (id) => {
            return getData(http, `/tasks/serviceOrganizations/unsealing/${id}`)
        },
        accept: (id) => {
            return postData(http, `/tasks/serviceOrganizations/unsealing/${id}/accept`)
        },
        decline: (id, data) => {
            return postData(http, `/tasks/serviceOrganizations/unsealing/${id}/decline`, data)
        },
        delete: (id) => {
            return deleteData(http, `/tasks/serviceOrganizations/unsealing/${id}`)
        },
        store: (data) => {
            return postData(http, `/tasks/serviceOrganizations/unsealing/store`, data)
        }
    }

    static sealing = {
        index: (queryString) => {
            queryString = queryString.replace('status', 'status_id');
            return getData(http, `/tasks/serviceOrganizations/sealing${queryString ? `?${queryString}` : ""}`);
        },
        view: (id) => {
            return getData(http, `/tasks/serviceOrganizations/sealing/${id}`)
        },
        accept: (id) => {
            return postData(http, `/tasks/serviceOrganizations/sealing/${id}/accept`)
        },
        decline: (id, data) => {
            return postData(http, `/tasks/serviceOrganizations/sealing/${id}/decline`, data)
        },
        delete: (id) => {
            return deleteData(http, `/tasks/serviceOrganizations/sealing/${id}`)
        },
        store: (data) => {
            return postData(http, `/tasks/serviceOrganizations/sealing/store`, data)
        }
    }
}