import React from 'react';
import {NavLink, Redirect, Route, Switch, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TabLinks from "../../../components/TabLinks";
import routePaths from "../../../navigation/admin/routePaths";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import {useLangContext} from "../../../i18n/ProvideLang";
import ServiceOrganizationsServices from '../../../services/admin/serviceOrganizationsServices';
import ServiceOrganizationsEditInformation from './ServiceOrganizationsEditInformation';
import ServiceOrganizationsEditAddresses from './ServiceOrganizationsEditAddresses';
import ServiceOrganizationsEditOperators from './ServiceOrganizationsEditOperators';
import ServiceOrganizationsEditSealers from './ServiceOrganizationsEditSealers';

const ServiceOrganizationsEdit = () => {
    const {id} = useParams();
    const {t} = useTranslation();

    const {info} = useFetchInfo(ServiceOrganizationsServices.view, id);
    const {lang} = useLangContext();

    return (
        <>
            <h1 className="headline-3">{t('labels.editing')} {info && " - " + info[`name_${lang}`]}</h1>
            <TabLinks>
                <NavLink activeClassName='active' exact to={routePaths.service_organizations.edit.information(id)}>{t('labels.information')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.service_organizations.edit.operators(id)}>{t('labels.operators')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.service_organizations.edit.sealers(id)}>{t('labels.sealers')}</NavLink>
                <NavLink activeClassName='active' exact to={routePaths.service_organizations.edit.addresses(id)}>{t('labels.addresses')}</NavLink>
            </TabLinks>
            <Switch>
                <Redirect exact from={routePaths.service_organizations.edit.index()} to={routePaths.service_organizations.edit.information()}/>
                <Route path={routePaths.service_organizations.edit.information()} component={ServiceOrganizationsEditInformation}/>
                <Route path={routePaths.service_organizations.edit.operators()} component={ServiceOrganizationsEditOperators}/>
                <Route path={routePaths.service_organizations.edit.sealers()} component={ServiceOrganizationsEditSealers}/>
                <Route path={routePaths.service_organizations.edit.addresses()} component={ServiceOrganizationsEditAddresses}/>
            </Switch>
        </>
    );
};

export default ServiceOrganizationsEdit;