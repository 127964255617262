import React from 'react';
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TabLinks from "../../../components/TabLinks";
import routePaths from "../../../navigation/serviceOrganizationOperator/routePaths";
import CompanyEditInformation from "./CompanyEditInformation";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import CompaniesServices from "../../../services/serviceOrganizationOperator/companiesServices";
import {useLangContext} from "../../../i18n/ProvideLang";

const CompanyEdit = () => {
    const {t} = useTranslation();

    const {info} = useFetchInfo(CompaniesServices.view);
    const {lang} = useLangContext();

    return (
        <>
            <h1 className="headline-3">{t('labels.editing')} {info && " - " + info[`name_${lang}`]}</h1>
            <TabLinks>
                <NavLink activeClassName='active' exact to={routePaths.company.information}>{t('labels.information')}</NavLink>
                {/*<NavLink activeClassName='active' exact to={routePaths.company.cities}>{t('labels.cities')}</NavLink>*/}
            </TabLinks>
            <Switch>
                <Redirect exact from={routePaths.company.index} to={routePaths.company.information}/>
                <Route exact path={routePaths.company.information} component={CompanyEditInformation}/>
                {/*<Route exact path={routePaths.company.cities} component={CompanyEditCities}/>*/}
            </Switch>
        </>
    );
};

export default CompanyEdit;