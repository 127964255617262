const routePaths = {
    home: {
        index: "/"
    },
    company: {
        index: `/company`,
        information: `/company/information`,
        operators: `/company/operators`,
        controllers: `/company/controllers`,
        sealers:`/company/sealers`,
        sealers_operators: `/company//sealer-operators`,
        analytics: `/company/analytics`,
        sectors: `/company/sectors`,
        cities: `/company/cities`,
        addresses: `/company/addresses`,
    },
    users: {
        index: "/users",
        children: {
            service_organizations_operators: {
                index: "/users/service-organizations-operators",
                create: "/users/service-organizations-operators/create",
                edit: (id) => `/users/service-organizations-operators/${id ? id : ":id"}/edit`
            },
            service_organizations_sealers: {
                index: "/users/service-organizations-sealers",
                create: "/users/service-organizations-sealers/create",
                edit: (id) => `/users/service-organizations-sealers/${id ? id : ":id"}/edit`
            },
        }
    },
    tasks:{
        index: "/tasksPlan",
        children:{
            tasks_primary_filling:{
                index: "/tasksPlan/tasks-primary-filling",
                edit: (id) => `/tasksPlan/tasks-primary-filling/${id ? id : ":id"}/edit`,
                create: "/tasksPlan/tasks-primary-filling/create",
                children: {
                    all: {
                        index: "/tasksPlan/tasks-primary-filling/all",
                    },
                    on_check: {
                        index: "/tasksPlan/tasks-primary-filling/on_check"
                    },
                    done: {
                        index: "/tasksPlan/tasks-primary-filling/done"
                    },
                    declined: {
                        index: "/tasksPlan/tasks-primary-filling/declined"
                    },
                    in_work:{
                        index: "/tasksPlan/tasks-primary-filling/in_work",
                    }
                }
            },
            tasks_for_unsealing:{
                index: "/tasksPlan/tasks-for-unsealing",
                edit: (id) => `/tasksPlan/tasks-for-unsealing/${id ? id : ":id"}/edit`,
                children: {
                    all: {
                        index: "/tasksPlan/tasks-for-unsealing/all"
                    },
                    on_check: {
                        index: "/tasksPlan/tasks-for-unsealing/on_check"
                    },
                    done: {
                        index: "/tasksPlan/tasks-for-unsealing/done"
                    },
                    declined: {
                        index: "/tasksPlan/tasks-for-unsealing/declined"
                    },
                    in_work:{
                        index: "/tasksPlan/tasks-for-unsealing/in_work"
                    }
                }
            },
            filling_tasks:{
                index: "/tasksPlan/filling-tasks",
                edit: (id) => `/tasksPlan/filling-tasks/${id ? id : ":id"}/edit`,
                children: {
                    all: {
                        index: "/tasksPlan/filling-tasks/all"
                    },
                    on_check: {
                        index: "/tasksPlan/filling-tasks/on_check"
                    },
                    done: {
                        index: "/tasksPlan/filling-tasks/done"
                    },
                    declined: {
                        index: "/tasksPlan/filling-tasks/declined"
                    },
                    in_work:{
                        index: "/tasksPlan/filling-tasks/in_work"
                    }
                }
            }
        }
    },
    addresses: {
        index: "/addresses",
        children: {
            regions: {
                index: "/addresses/regions",
                create: "/addresses/regions/create",
                edit: {
                    index: (id) => `/addresses/regions/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/regions/${id ? id : ":id"}/edit/information`,
                    cities: (id) => `/addresses/regions/${id ? id : ":id"}/edit/cities`
                }
            },
            cities: {
                index: "/addresses/cities",
                create: "/addresses/cities/create",
                edit: {
                    index: (id) => `/addresses/cities/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/cities/${id ? id : ":id"}/edit/information`,
                    districts: (id) => `/addresses/cities/${id ? id : ":id"}/edit/districts`
                }
            },
            districts: {
                index: "/addresses/districts",
                create: "/addresses/districts/create",
                edit: {
                    index: (id) => `/addresses/districts/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/districts/${id ? id : ":id"}/edit/information`,
                    microdistricts: (id) => `/addresses/districts/${id ? id : ":id"}/edit/microdistricts`,
                    streets: (id) => `/addresses/districts/${id ? id : ":id"}/edit/streets`
                }
            },
            microdistricts: {
                index: "/addresses/microdistricts",
                create: "/addresses/microdistricts/create",
                edit: {
                    index: (id) => `/addresses/microdistricts/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/microdistricts/${id ? id : ":id"}/edit/information`,
                    streets: (id) => `/addresses/microdistricts/${id ? id : ":id"}/edit/streets`
                }
            },
            streets: {
                index: "/addresses/streets",
                create: "/addresses/streets/create",
                edit: {
                    index: (id) => `/addresses/streets/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/streets/${id ? id : ":id"}/edit/information`,
                    houses: (id) => `/addresses/streets/${id ? id : ":id"}/edit/houses`,
                    sectors: (id) => `/addresses/streets/${id ? id : ":id"}/edit/sectors`,
                    controllers: (id) => `/addresses/streets/${id ? id : ":id"}/edit/controllers`
                }
            },
            houses: {
                index: "/addresses/houses",
                create: "/addresses/houses/create",
                edit: {
                    index: (id) => `/addresses/houses/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/houses/${id ? id : ":id"}/edit/information`,
                    entrances: (id) => `/addresses/houses/${id ? id : ":id"}/edit/entrances`,
                    apartments: (id) => `/addresses/houses/${id ? id : ":id"}/edit/apartments`,
                    bankbooks: (id) => `/addresses/houses/${id ? id : ":id"}/edit/bankbooks`,
                    sectors: (id) => `/addresses/houses/${id ? id : ":id"}/edit/sectors`,
                    controllers: (id) => `/addresses/houses/${id ? id : ":id"}/edit/controllers`
                }
            },
            entrances: {
                index: "/addresses/entrances",
                create: "/addresses/entrances/create",
                edit: {
                    index: (id) => `/addresses/entrances/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/entrances/${id ? id : ":id"}/edit/information`,
                    floors: (id) => `/addresses/entrances/${id ? id : ":id"}/edit/floors`,
                    apartments: (id) => `/addresses/entrances/${id ? id : ":id"}/edit/apartments`
                }
            },
            floors: {
                index: "/addresses/floors",
                create: "/addresses/floors/create",
                edit: {
                    index: (id) => `/addresses/floors/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/floors/${id ? id : ":id"}/edit/information`,
                    apartments: (id) => `/addresses/floors/${id ? id : ":id"}/edit/apartments`
                }
            },
            apartments: {
                index: "/addresses/apartments",
                create: "/addresses/apartments/create",
                edit: {
                    index: (id) => `/addresses/apartments/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/apartments/${id ? id : ":id"}/edit/information`,
                    bankbooks: (id) => `/addresses/apartments/${id ? id : ":id"}/edit/bankbooks`,
                    controllers: (id) => `/addresses/apartments/${id ? id : ":id"}/edit/controllers`
                }
            }
        }
    },
    sectors: {
        index: "/sectors",
        create: "/sectors/create",
        edit: {
            index: (id) => `/sectors/${id ? id : ":id"}/edit`,
            information: (id) => `/sectors/${id ? id : ":id"}/edit/information`,
            controllers: (id) => `/sectors/${id ? id : ":id"}/edit/controllers`,
            operators: (id) => `/sectors/${id ? id : ":id"}/edit/operators`,
            streets: (id) => `/sectors/${id ? id : ":id"}/edit/streets`,
            houses: (id) => `/sectors/${id ? id : ":id"}/edit/houses`
        }
    },
    bankbooks: {
        index: "/bankbooks",
        create: "/bankbooks/create",
        edit: {
            index: (id) => `/bankbooks/${id ? id : ":id"}/edit`,
            information: (id) => `/bankbooks/${id ? id : ":id"}/edit/information`,
            devices: (id) => `/bankbooks/${id ? id : ":id"}/edit/devices`,
            documents: (id) => `/bankbooks/${id ? id : ":id"}/edit/documents`,
        }
    },
    devices: {
        index: "/devices",
        create: "/devices/create",
        edit: {
            index: (id) => `/devices/${id ? id : ":id"}/edit`,
            information: (id) => `/devices/${id ? id : ":id"}/edit/information`,
            indications: (id) => `/devices/${id ? id : ":id"}/edit/indications`,
            notifications: (id) => `/devices/${id ? id : ":id"}/edit/notifications`,
            documents: (id) => `/devices/${id ? id : ":id"}/edit/documents`,
            histories: (id) => `/devices/${id ? id : ":id"}/edit/histories`,
        }
    },
    data_import: {
        index: "/data-import",
        children: {
            addresses: {
                index: "/data-import/addresses"
            },
            devices: {
                index: "/data-import/devices"
            },
            indications: {
                index: "/data-import/indications"
            },
        }
    }
}

export default routePaths;