export const apiBaseUrl = (process.env.NODE_ENV === 'development' ? 'https://esep.panama.kz' : '') + '/api/admin';

export const apiAdminUrl = apiBaseUrl + '/admin';
export const apiOperatorUrl = apiBaseUrl + '/companyOperator';
export const apiLaboratoryAdministratorUrl = apiBaseUrl + '/laboratoryAdministrator';
export const apiCallOperatorUrl = apiBaseUrl + '/esepCallCenterOperator';
export const apiEsepAdminUrl = apiBaseUrl + '/esepAdmin';
export const apiAnalyticOperatorUrl = apiBaseUrl + '/analytic';
export const sealerOperatorUrl = apiBaseUrl + '/sealerOperator';
export const serviceOrganizationOperator = apiBaseUrl + '/serviceOrganizationOperator';

export const interceptorsConfig = (config) => {
    const token = localStorage.getItem('token');
    const lang = localStorage.getItem('i18nextLng');

    config.headers.Authorization = token ? `Bearer ${token}` : null;
    config.headers.language = lang ? lang : 'ru';

    return config;
}