import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import routePaths from "./routePaths";
import generalRoutePaths from "../general/routePaths";
import Subscribers from "../../pages/admin/Users/Subscribers";
import SubscriberCreate from "../../pages/admin/Users/Subscribers/SubscriberCreate";
import SubscriberEdit from "../../pages/admin/Users/Subscribers/SubscriberEdit";
import LabAdministrators from "../../pages/admin/Users/LabAdministrators";
import LabAdministratorCreate from "../../pages/admin/Users/LabAdministrators/LabAdministratorCreate";
import LabAdministratorEdit from "../../pages/admin/Users/LabAdministrators/LabAdministratorEdit";
import LabSpecialists from "../../pages/admin/Users/LabSpecialists";
import LabSpecialistCreate from "../../pages/admin/Users/LabSpecialists/LabSpecialistCreate";
import LabSpecialistEdit from "../../pages/admin/Users/LabSpecialists/LabSpecialistEdit";
import Controllers from "../../pages/admin/Users/Controllers";
import ControllerCreate from "../../pages/admin/Users/Controllers/ControllerCreate";
import ControllerEdit from "../../pages/admin/Users/Controllers/ControllerEdit";
import Administrators from "../../pages/admin/Users/Administrators";
import AdministratorCreate from "../../pages/admin/Users/Administrators/AdministratorCreate";
import AdministratorEdit from "../../pages/admin/Users/Administrators/AdministratorEdit";
import Operators from "../../pages/admin/Users/Operators";
import OperatorCreate from "../../pages/admin/Users/Operators/OperatorCreate";
import OperatorEdit from "../../pages/admin/Users/Operators/OperatorEdit";
import Laboratories from "../../pages/admin/Laboratoires";
import LaboratoryCreate from "../../pages/admin/Laboratoires/LaboratoryCreate";
import LaboratoryEdit from "../../pages/admin/Laboratoires/LaboratoryEdit";
import Companies from "../../pages/admin/Companies";
import CompanyCreate from "../../pages/admin/Companies/CompanyCreate";
import CompanyEdit from "../../pages/admin/Companies/CompanyEdit";
import Sectors from "../../pages/admin/Sectors";
import SectorCreate from "../../pages/admin/Sectors/SectorCreate";
import SectorEdit from "../../pages/admin/Sectors/SectorEdit";
import Regions from "../../pages/admin/Addresses/Regions";
import RegionCreate from "../../pages/admin/Addresses/Regions/RegionCreate";
import RegionEdit from "../../pages/admin/Addresses/Regions/RegionEdit";
import Cities from "../../pages/admin/Addresses/Cities";
import CityCreate from "../../pages/admin/Addresses/Cities/CityCreate";
import CityEdit from "../../pages/admin/Addresses/Cities/CityEdit";
import Streets from "../../pages/admin/Addresses/Streets";
import StreetCreate from "../../pages/admin/Addresses/Streets/StreetCreate";
import StreetEdit from "../../pages/admin/Addresses/Streets/StreetEdit";
import Houses from "../../pages/admin/Addresses/Houses";
import HouseCreate from "../../pages/admin/Addresses/Houses/HouseCreate";
import HouseEdit from "../../pages/admin/Addresses/Houses/HouseEdit";
// import Entrances from "../../pages/admin/Addresses/Entrances";
// import EntranceCreate from "../../pages/admin/Addresses/Entrances/EntranceCreate";
// import EntranceEdit from "../../pages/admin/Addresses/Entrances/EntranceEdit";
// import Floors from "../../pages/admin/Addresses/Floors";
// import FloorCreate from "../../pages/admin/Addresses/Floors/FloorCreate";
// import FloorEdit from "../../pages/admin/Addresses/Floors/FloorEdit";
import Apartments from "../../pages/admin/Addresses/Apartments";
import ApartmentCreate from "../../pages/admin/Addresses/Apartments/ApartmentCreate";
import ApartmentEdit from "../../pages/admin/Addresses/Apartments/ApartmentEdit";
import Bankbooks from "../../pages/admin/Bankbooks";
import BankbookCreate from "../../pages/admin/Bankbooks/BankbookCreate";
import BankbookEdit from "../../pages/admin/Bankbooks/BankbookEdit";
import Devices from "../../pages/admin/Devices";
import DeviceCreate from "../../pages/admin/Devices/DeviceCreate";
import DeviceEdit from "../../pages/admin/Devices/DeviceEdit";
import Indications from "../../pages/admin/Indications";
import IndicationCreate from "../../pages/admin/Indications/IndicationCreate";
import IndicationEdit from "../../pages/admin/Indications/IndicationEdit";
import Dictionaries from "../../pages/admin/Dictionaries";
import DictionariesServices from "../../services/admin/dictionariesServices";
import DictionaryCreate from "../../pages/admin/Dictionaries/DictionaryCreate";
import DictionaryEdit from "../../pages/admin/Dictionaries/DictionaryEdit";
import Notifications from "../../pages/admin/Notifications";
import NotificationCreate from "../../pages/admin/Notifications/NotificationCreate";
import NotificationDetail from "../../pages/admin/Notifications/NotificationDetail";
import {useTranslation} from "react-i18next";
import DefaultLayout from "../../layouts/DefaultLayout";
import Profile from "../../pages/general/Profile";
import Applications from "../../pages/admin/Applications";
import Application from "../../pages/admin/Applications/Application";
import Invoices from "../../pages/admin/Invoices";
import Districts from "../../pages/admin/Addresses/Districts";
import DistrictCreate from "../../pages/admin/Addresses/Districts/DistrictCreate";
import DistrictEdit from "../../pages/admin/Addresses/Districts/DistrictEdit";
import Microdistricts from "../../pages/admin/Addresses/Microdistricts";
import MicrodistrictCreate from "../../pages/admin/Addresses/Microdistricts/MicrodistrictCreate";
import MicrodistrictEdit from "../../pages/admin/Addresses/Microdistricts/MicrodistrictEdit";
import AddressesImport from "../../pages/admin/Import/Addresses";
import DevicesImport from "../../pages/admin/Import/Devices";
import IndicationsImport from "../../pages/admin/Import/Indications";
import ServiceTypeCreate from "../../pages/admin/ServiceTypes/ServiceTypeCreate";
import ServiceTypeEdit from "../../pages/admin/ServiceTypes/ServiceTypeEdit";
import ApplicationCreate from "../../pages/admin/Applications/ApplicationCreate";
import IndicationsSettingsApp from "../../pages/admin/Settings/IndicationSettingsApp"
import SuperAdministrators from '../../pages/admin/Users/SuperAdministrators';
import SuperAdministratorCreate from '../../pages/admin/Users/SuperAdministrators/SuperAdministratorCreate';
import SuperAdministratorEdit from '../../pages/admin/Users/SuperAdministrators/SuperAdministratorEdit';
import AnaliticOperators from '../../pages/admin/Users/AnaliticsOperators';
import AnaliticOperatorCreate from '../../pages/admin/Users/AnaliticsOperators/AnaliticOperatorCreate';
import AnaliticOperatorEdit from '../../pages/admin/Users/AnaliticsOperators/AnaliticOperatorEdit';
import CallOperators from '../../pages/admin/Users/CallOperators';
import CallOperatorCreate from '../../pages/admin/Users/CallOperators/CallOperatorCreate';
import CallOperatorEdit from '../../pages/admin/Users/CallOperators/CallOperatorEdit';
import ModelChangesBankbooks from '../../pages/admin/ModelChanges/Bankbooks';
import ModelChangesBankbookEdit from '../../pages/admin/ModelChanges/Bankbooks/ModelChangesBankbookEdit';
import ModelChangesDevices from '../../pages/admin/ModelChanges/Devices';
import ModelChangesDevicesEdit from '../../pages/admin/ModelChanges/Devices/ModelChangesDevicesEdit';
import SealingOperators from '../../pages/admin/Users/SealingOperators';
import SealingSpecialists from '../../pages/admin/Users/SealingSpecialists';
import SealingOperatorCreate from '../../pages/admin/Users/SealingOperators/SealingOperatorCreate';
import SealingSpecialistCreate from '../../pages/admin/Users/SealingSpecialists/SealingSpecialistCreate';
import TasksCompanies from '../../pages/admin/Tasks/Companies';
import TaskComponiesEdit from '../../pages/admin/Tasks/Companies/TaskComponiesEdit';
import TaskController from '../../pages/admin/Tasks/Controllers';
import TaskControllerEdit from '../../pages/admin/Tasks/Controllers/TaskControllerEdit';
import TasksStatics from '../../pages/admin/Tasks/Statistic';
import TaskSearlers from '../../pages/admin/Tasks/Searlers';
import SealingOperatorEdit from '../../pages/admin/Users/SealingOperators/SealingOperatorEdit';
import SealingSpecialistEdit from '../../pages/admin/Users/SealingSpecialists/SealingSpecialistEdit';
import ApplicationRejectReason from '../../pages/admin/ApplicationRejectReason';
import ApplicationRejectReasonEdit from '../../pages/admin/ApplicationRejectReason/ApplicationRejectReasonEdit';
import ApplicationRejectReasonCreate from '../../pages/admin/ApplicationRejectReason/ApplicationRejectReasonCreate';
import TaskSealersEdit from '../../pages/admin/Tasks/Searlers/TaskSealersEdit';
import TasksPrimaryFilling from '../../pages/admin/Tasks/TasksPrimaryFilling';
import TasksPrimaryFillingEdit from '../../pages/admin/Tasks/TasksPrimaryFilling/TasksPrimaryFillingEdit';
import FillingTasks from '../../pages/admin/Tasks/FillingTasks';
import FillingTasksEdit from '../../pages/admin/Tasks/FillingTasks/FillingTasksEdit';
import TasksUnsealing from '../../pages/admin/Tasks/TasksUnsealing';
import TasksUnsealingEdit from '../../pages/admin/Tasks/TasksUnsealing/TasksUnsealingEdit';
import DevicesDeleteImport from '../../pages/admin/Import/DevicesDeleteImport';
import SealingRejectReason from '../../pages/admin/SealingRejectReason';
import SealingRejectReasonCreate from '../../pages/admin/SealingRejectReason/SealingRejectReasonCreate';
import SealingRejectReasonEdit from '../../pages/admin/SealingRejectReason/SealingRejectReasonEdit';
import DeviceRejectReason from '../../pages/admin/DeviceRejectReason';
import DeviceRejectReasonEdit from '../../pages/admin/DeviceRejectReason/DeviceRejectReasonEdit';
import DeviceRejectReasonCreate from '../../pages/admin/DeviceRejectReason/DeviceRejectReasonCreate';
import ServiceOrganizationsOperators from '../../pages/admin/Users/ServiceOrganizationsOperators';
import ServiceOrganizationsOperatorsEdit from '../../pages/admin/Users/ServiceOrganizationsOperators/ServiceOrganizationsOperatorsEdit';
import ServiceOrganizationsOperatorsCreate from '../../pages/admin/Users/ServiceOrganizationsOperators/ServiceOrganizationsOperatorsCreate';
import ServiceOrganizationsSealers from '../../pages/admin/Users/ServiceOrganizationsSealers';
import ServiceOrganizationsSealersCreate from '../../pages/admin/Users/ServiceOrganizationsSealers/ServiceOrganizationsSealersCreate';
import ServiceOrganizationsSealersEdit from '../../pages/admin/Users/ServiceOrganizationsSealers/ServiceOrganizationsSealersEdit';
import ServiceOrganizations from '../../pages/admin/ServiceOrganizations';
import ServiceOrganizationsCreate from '../../pages/admin/ServiceOrganizations/ServiceOrganizationsCreate';
import ServiceOrganizationsEdit from '../../pages/admin/ServiceOrganizations/ServiceOrganizationsEdit';
import TasksPrimaryFillingServices from '../../pages/admin/TasksServiceOrganizations/TasksPrimaryFilling';
import ServicesTasksPrimaryFillingEdit from '../../pages/admin/TasksServiceOrganizations/TasksPrimaryFilling/ServicesTasksPrimaryFillingEdit';
import FillingTasksServices from '../../pages/admin/TasksServiceOrganizations/FillingTasks';
import FillingTasksEditServices from '../../pages/admin/TasksServiceOrganizations/FillingTasks/FillingTasksEditServices';
import TasksUnsealingServices from '../../pages/admin/TasksServiceOrganizations/TasksUnsealing';
import TasksUnsealingEditServices from '../../pages/admin/TasksServiceOrganizations/TasksUnsealing/TasksUnsealingEditServices';

const Routes = () => {
    const {t} = useTranslation();

    const sidebarItems = [
        {
            title: t('sidebar.users.title'),
            url: routePaths.users.index,
            icon: "users",
            children: [
                {
                    title: t('sidebar.users.children.super_administrators'),
                    url: routePaths.users.children.super_administrators.index
                },
                {
                    title: t('sidebar.users.children.administrators'),
                    url: routePaths.users.children.administrators.index
                },
                {
                    title: t('sidebar.users.children.analitics_operators'),
                    url: routePaths.users.children.analitic_operators.index
                },
                {
                    title: t('sidebar.users.children.operators'),
                    url: routePaths.users.children.operators.index
                },
                {
                    title: t('sidebar.users.children.call_operators'),
                    url: routePaths.users.children.call_operators.index
                },
                {
                    title: t('sidebar.users.children.controllers'),
                    url: routePaths.users.children.controllers.index
                },
                {
                    title: t('sidebar.users.children.sealer_operators'),
                    url: routePaths.users.children.sealer_operator.index
                },
                {
                    title: t('sidebar.users.children.sealing_specialists'),
                    url: routePaths.users.children.sealing_specialists.index
                },
                {
                    title: t('sidebar.users.children.lab_administrators'),
                    url: routePaths.users.children.lab_administrators.index
                },
                {
                    title: t('sidebar.users.children.lab_specialists'),
                    url: routePaths.users.children.lab_specialists.index
                },
                {
                    title: t('sidebar.users.children.service_organizations_operators'),
                    url: routePaths.users.children.service_organizations_operators.index
                },
                {
                    title: t('sidebar.users.children.service_organizations_sealers'),
                    url: routePaths.users.children.service_organizations_sealers.index
                },
                {
                    title: t('sidebar.users.children.subscribers'),
                    url: routePaths.users.children.subscribers.index
                }
            ]
        },
        {
            title: t('sidebar.companies.title'),
            url: routePaths.companies.index,
            icon: "briefcase"
        },
        {
            title: t('sidebar.service_organizations.title'),
            url: routePaths.service_organizations.index,
            icon: "briefcase"
        },
        {
            title: t('sidebar.laboratories.title'),
            url: routePaths.laboratories.index,
            icon: "physics"
        },
        {
            title: t('sidebar.sectors.title'),
            url: routePaths.sectors.index,
            icon: "tool-crop"
        },
        {
            title: t('sidebar.addresses.title'),
            url: routePaths.addresses.index,
            icon: "marker",
            children: [
                {
                    title: t('sidebar.addresses.children.regions'),
                    url: routePaths.addresses.children.regions.index
                },
                {
                    title: t('sidebar.addresses.children.cities'),
                    url: routePaths.addresses.children.cities.index
                },
                {
                    title: t('sidebar.addresses.children.districts'),
                    url: routePaths.addresses.children.districts.index
                },
                {
                    title: t('sidebar.addresses.children.microdistricts'),
                    url: routePaths.addresses.children.microdistricts.index
                },
                {
                    title: t('sidebar.addresses.children.streets'),
                    url: routePaths.addresses.children.streets.index
                },
                {
                    title: t('sidebar.addresses.children.houses'),
                    url: routePaths.addresses.children.houses.index
                },
                {
                    title: t('sidebar.addresses.children.apartments'),
                    url: routePaths.addresses.children.apartments.index
                }
            ]
        },
        {
            title: t('sidebar.bankbooks.title'),
            url: routePaths.bankbooks.index,
            icon: "badge"
        },
        {
            title: t('sidebar.devices.title'),
            url: routePaths.devices.index,
            icon: "dashboard"
        },
        {
            title: t('sidebar.indications.title'),
            url: routePaths.indications.index,
            icon: "receipt",
            children: [
                {
                    title: t('sidebar.indications.children.on_check'),
                    url: routePaths.indications.children.on_check.index
                },
                {
                    title: t('sidebar.indications.children.approved'),
                    url: routePaths.indications.children.approved.index
                },
                {
                    title: t('sidebar.indications.children.declined'),
                    url: routePaths.indications.children.declined.index
                },
                {
                    title: t('sidebar.indications.children.all'),
                    url: routePaths.indications.children.all.index
                }
            ]
        },
        {
            title: t('sidebar.tasks.title'),
            url: routePaths.tasks.index,
            icon: "tasks",
            children: [
                {
                    title: t('sidebar.tasks.children.tasks_primary_filling.index'),
                    url: routePaths.tasks.children.tasks_primary_filling.index,
                    children: [
                        {
                            title: t('sidebar.tasks.children.tasks_primary_filling.children.in_work'),
                            url: routePaths.tasks.children.tasks_primary_filling.children.in_work.index
                        },
                        {
                            title: t('sidebar.tasks.children.tasks_primary_filling.children.on_check'),
                            url: routePaths.tasks.children.tasks_primary_filling.children.on_check.index
                        },
                        {
                            title: t('sidebar.tasks.children.tasks_primary_filling.children.done'),
                            url: routePaths.tasks.children.tasks_primary_filling.children.done.index
                        },
                        {
                            title: t('sidebar.tasks.children.tasks_primary_filling.children.declined'),
                            url: routePaths.tasks.children.tasks_primary_filling.children.declined.index
                        },
                        {
                            title: t('sidebar.tasks.children.tasks_primary_filling.children.all'),
                            url: routePaths.tasks.children.tasks_primary_filling.children.all.index
                        },
                    ]
                },
                {
                    title: t('sidebar.tasks.children.tasks_for_unsealing.index'),
                    url: routePaths.tasks.children.tasks_for_unsealing.index,
                    children: [
                        {
                            title: t('sidebar.tasks.children.tasks_for_unsealing.children.in_work'),
                            url: routePaths.tasks.children.tasks_for_unsealing.children.in_work.index
                        },
                        {
                            title: t('sidebar.tasks.children.tasks_for_unsealing.children.on_check'),
                            url: routePaths.tasks.children.tasks_for_unsealing.children.on_check.index
                        },
                        {
                            title: t('sidebar.tasks.children.tasks_for_unsealing.children.done'),
                            url: routePaths.tasks.children.tasks_for_unsealing.children.done.index
                        },
                        {
                            title: t('sidebar.tasks.children.tasks_for_unsealing.children.declined'),
                            url: routePaths.tasks.children.tasks_for_unsealing.children.declined.index
                        },
                        {
                            title: t('sidebar.tasks.children.tasks_for_unsealing.children.all'),
                            url: routePaths.tasks.children.tasks_for_unsealing.children.all.index
                        },
                    ]
                },
                {
                    title: t('sidebar.tasks.children.filling_tasks.index'),
                    url: routePaths.tasks.children.filling_tasks.index,
                    children: [
                        {
                            title: t('sidebar.tasks.children.filling_tasks.children.in_work'),
                            url: routePaths.tasks.children.filling_tasks.children.in_work.index
                        },
                        {
                            title: t('sidebar.tasks.children.filling_tasks.children.on_check'),
                            url: routePaths.tasks.children.filling_tasks.children.on_check.index
                        },
                        {
                            title: t('sidebar.tasks.children.filling_tasks.children.done'),
                            url: routePaths.tasks.children.filling_tasks.children.done.index
                        },
                        {
                            title: t('sidebar.tasks.children.filling_tasks.children.declined'),
                            url: routePaths.tasks.children.filling_tasks.children.declined.index
                        },
                        {
                            title: t('sidebar.tasks.children.filling_tasks.children.all'),
                            url: routePaths.tasks.children.filling_tasks.children.all.index
                        },
                    ]
                },
                {
                    title: t('sidebar.tasks.children.assignment_tasks.index'),
                    url: routePaths.tasks.children.companies.index,
                    children: [
                        {
                            title: t('sidebar.tasks.children.assignment_tasks.children.companies'),
                            url: routePaths.tasks.children.companies.index
                        },
                        {
                            title: t('sidebar.tasks.children.assignment_tasks.children.controllers'),
                            url: routePaths.tasks.children.controllers.index
                        },
                        {
                            title: t('sidebar.tasks.children.assignment_tasks.children.sealers'),
                            url: routePaths.tasks.children.sealers.index
                        },
                    ]
                },
                {
                    title: t('sidebar.tasks.children.statistics.index'),
                    url: routePaths.tasks.children.statistic.index,
                    children:[
                        {
                            title: t('sidebar.tasks.children.statistics.children.controllers'),
                            url: routePaths.tasks.children.statistic.children.controllers.index
                        },
                        {
                            title: t('sidebar.tasks.children.statistics.children.sealers'),
                            url: routePaths.tasks.children.statistic.children.sealers.index
                        },
                    ]
                }
            ]
        },
        {
            title: t('sidebar.tasks_service_organizations.index'),
            url: routePaths.tasks_service_organizations.index,
            icon: "tasks",
            children: [
                {
                    title: t('sidebar.tasks_service_organizations.children.tasks_primary_filling.index'),
                    url: routePaths.tasks_service_organizations.children.tasks_primary_filling.index,
                    children: [
                        {
                            title: t('sidebar.tasks_service_organizations.children.tasks_primary_filling.children.in_work'),
                            url: routePaths.tasks_service_organizations.children.tasks_primary_filling.children.in_work.index
                        },
                        {
                            title: t('sidebar.tasks_service_organizations.children.tasks_primary_filling.children.on_check'),
                            url: routePaths.tasks_service_organizations.children.tasks_primary_filling.children.on_check.index
                        },
                        {
                            title: t('sidebar.tasks_service_organizations.children.tasks_primary_filling.children.done'),
                            url: routePaths.tasks_service_organizations.children.tasks_primary_filling.children.done.index
                        },
                        {
                            title: t('sidebar.tasks_service_organizations.children.tasks_primary_filling.children.declined'),
                            url: routePaths.tasks_service_organizations.children.tasks_primary_filling.children.declined.index
                        },
                        {
                            title: t('sidebar.tasks_service_organizations.children.tasks_primary_filling.children.all'),
                            url: routePaths.tasks_service_organizations.children.tasks_primary_filling.children.all.index
                        },
                    ]
                },
                {
                    title: t('sidebar.tasks_service_organizations.children.tasks_for_unsealing.index'),
                    url: routePaths.tasks_service_organizations.children.tasks_for_unsealing.index,
                    children: [
                        {
                            title: t('sidebar.tasks_service_organizations.children.tasks_for_unsealing.children.in_work'),
                            url: routePaths.tasks_service_organizations.children.tasks_for_unsealing.children.in_work.index
                        },
                        {
                            title: t('sidebar.tasks_service_organizations.children.tasks_for_unsealing.children.on_check'),
                            url: routePaths.tasks_service_organizations.children.tasks_for_unsealing.children.on_check.index
                        },
                        {
                            title: t('sidebar.tasks_service_organizations.children.tasks_for_unsealing.children.done'),
                            url: routePaths.tasks_service_organizations.children.tasks_for_unsealing.children.done.index
                        },
                        {
                            title: t('sidebar.tasks_service_organizations.children.tasks_for_unsealing.children.declined'),
                            url: routePaths.tasks_service_organizations.children.tasks_for_unsealing.children.declined.index
                        },
                        {
                            title: t('sidebar.tasks_service_organizations.children.tasks_for_unsealing.children.all'),
                            url: routePaths.tasks_service_organizations.children.tasks_for_unsealing.children.all.index
                        },
                    ]
                },
                {
                    title: t('sidebar.tasks_service_organizations.children.filling_tasks.index'),
                    url: routePaths.tasks_service_organizations.children.filling_tasks.index,
                    children: [
                        {
                            title: t('sidebar.tasks_service_organizations.children.filling_tasks.children.in_work'),
                            url: routePaths.tasks_service_organizations.children.filling_tasks.children.in_work.index
                        },
                        {
                            title: t('sidebar.tasks_service_organizations.children.filling_tasks.children.on_check'),
                            url: routePaths.tasks_service_organizations.children.filling_tasks.children.on_check.index
                        },
                        {
                            title: t('sidebar.tasks_service_organizations.children.filling_tasks.children.done'),
                            url: routePaths.tasks_service_organizations.children.filling_tasks.children.done.index
                        },
                        {
                            title: t('sidebar.tasks_service_organizations.children.filling_tasks.children.declined'),
                            url: routePaths.tasks_service_organizations.children.filling_tasks.children.declined.index
                        },
                        {
                            title: t('sidebar.tasks_service_organizations.children.filling_tasks.children.all'),
                            url: routePaths.tasks_service_organizations.children.filling_tasks.children.all.index
                        },
                    ]
                },
            ]
        },
        {
            title: t('sidebar.dictionaries.title'),
            url: routePaths.dictionaries.index,
            icon: "folder",
            children: [
                {
                    title: t('sidebar.dictionaries.children.resource_types'),
                    url: routePaths.dictionaries.children.resource_types.index
                },
                {
                    title: t('sidebar.dictionaries.children.improvements'),
                    url: routePaths.dictionaries.children.improvements.index
                },
                {
                    title: t('sidebar.dictionaries.children.service_types'),
                    url: routePaths.dictionaries.children.service_types.index
                },
                {
                    title: t('sidebar.dictionaries.children.application_reject_reason'),
                    url: routePaths.dictionaries.children.application_reject_reason.index
                },
                {
                    title: t('sidebar.dictionaries.children.sealing_reject_reason'),
                    url: routePaths.dictionaries.children.sealing_reject_reason.index
                },
                {
                    title: t('sidebar.dictionaries.children.device_reject_reason'),
                    url: routePaths.dictionaries.children.device_reject_reason.index
                }
            ]
        },
        {
            title: t('sidebar.requests.title'),
            url: routePaths.applications.index,
            icon: "list",
            children: [
                {
                    title: t('sidebar.applications.children.all'),
                    url: routePaths.applications.children.all.index
                },
                {
                    title: t('sidebar.applications.children.under_consideration'),
                    url: routePaths.applications.children.under_consideration.index
                },
                {
                    title: t('sidebar.applications.children.rejected'),
                    url: routePaths.applications.children.rejected.index
                },
                {
                    title: t('sidebar.applications.children.awaiting_payment'),
                    url: routePaths.applications.children.awaiting_payment.index
                },
                {
                    title: t('sidebar.applications.children.awaiting_completion'),
                    url: routePaths.applications.children.awaiting_completion.index
                },
                {
                    title: t('sidebar.applications.children.in_work'),
                    url: routePaths.applications.children.in_work.index
                },
                {
                    title: t('sidebar.applications.children.cancelled'),
                    url: routePaths.applications.children.cancelled.index
                },
                {
                    title: t('sidebar.applications.children.done'),
                    url: routePaths.applications.children.done.index
                }
            ]
        },
        {
            title: t('sidebar.change_requests.title'),
            url: routePaths.model_сhanges.index,
            icon: "model-change",
            children: [
                {
                    title: t('sidebar.change_requests.children.bankbooks'),
                    url: routePaths.model_сhanges.children.bankbooks.all.index,
                    children: [
                        {
                            title: t('sidebar.indications.children.on_check'),
                            url: routePaths.model_сhanges.children.bankbooks.on_check.index
                        },
                        {
                            title: t('sidebar.indications.children.approved'),
                            url: routePaths.model_сhanges.children.bankbooks.approved.index
                        },
                        {
                            title: t('sidebar.indications.children.declined'),
                            url: routePaths.model_сhanges.children.bankbooks.declined.index
                        },
                        {
                            title: t('sidebar.indications.children.all'),
                            url: routePaths.model_сhanges.children.bankbooks.all.index
                        }
                    ]
                },
                {
                    title: t('sidebar.change_requests.children.devices'),
                    url: routePaths.model_сhanges.children.devices.all.index,
                    children: [
                        {
                            title: t('sidebar.indications.children.on_check'),
                            url: routePaths.model_сhanges.children.devices.on_check.index
                        },
                        {
                            title: t('sidebar.indications.children.approved'),
                            url: routePaths.model_сhanges.children.devices.approved.index
                        },
                        {
                            title: t('sidebar.indications.children.declined'),
                            url: routePaths.model_сhanges.children.devices.declined.index
                        },
                        {
                            title: t('sidebar.indications.children.all'),
                            url: routePaths.model_сhanges.children.devices.all.index
                        }
                    ]
                }
            ]
            
        },
        {
            title: t('sidebar.invoices.title'),
            url: routePaths.invoices.index,
            icon: "receipt"
        },
        {
            title: t('sidebar.settings.title'),
            url: routePaths.settings.index,
            icon: "settings",
            children: [
                {
                    title: t('sidebar.settings.children.indication_settings_app'),
                    url: routePaths.settings.children.indication_settings_app.index
                }
            ]
        },
        {
            title: t('sidebar.notifications.title'),
            url: routePaths.notifications.index,
            icon: "bell"
        },
        {
            title: t('sidebar.data_import.title'),
            url: routePaths.data_import.index,
            icon: "upload",
            children: [
                {
                    title: t('sidebar.data_import.children.addresses'),
                    url: routePaths.data_import.children.addresses.index
                },
                {
                    title: t('sidebar.data_import.children.devices'),
                    url: routePaths.data_import.children.devices.index
                },
                {
                    title: t('sidebar.data_import.children.indications'),
                    url: routePaths.data_import.children.indications.index
                },
                {
                    title: t('sidebar.data_import.children.devices_to_delete'),
                    url: routePaths.data_import.children.devices_to_delete.index
                }
            ]
        }
    ]

    return (
        <DefaultLayout sidebarItems={sidebarItems}>
            <Switch>
                <Redirect exact from={routePaths.home.index} to={routePaths.users.children.subscribers.index}/>
                {/*Profile*/}
                <Route exact path={generalRoutePaths.profile.index} component={Profile}/>
                {/*Users*/}
                <Redirect exact from={routePaths.users.index} to={routePaths.users.children.subscribers.index}/>
                {/*Users - Subscribers*/}
                <Route exact path={routePaths.users.children.subscribers.index} component={Subscribers}/>
                <Route exact path={routePaths.users.children.subscribers.create} component={SubscriberCreate}/>
                <Route path={routePaths.users.children.subscribers.edit()} component={SubscriberEdit}/>
                {/*Users - Lab Administrators*/}
                <Route exact path={routePaths.users.children.lab_administrators.index} component={LabAdministrators}/>
                <Route exact path={routePaths.users.children.lab_administrators.create}
                       component={LabAdministratorCreate}/>
                <Route exact path={routePaths.users.children.lab_administrators.edit()}
                       component={LabAdministratorEdit}/>
                {/*Users - Lab Specialists*/}
                <Route exact path={routePaths.users.children.lab_specialists.index} component={LabSpecialists}/>
                <Route exact path={routePaths.users.children.lab_specialists.create} component={LabSpecialistCreate}/>
                <Route exact path={routePaths.users.children.lab_specialists.edit()} component={LabSpecialistEdit}/>
                {/*Users - Controllers*/}
                <Route exact path={routePaths.users.children.controllers.index} component={Controllers}/>
                <Route exact path={routePaths.users.children.controllers.create} component={ControllerCreate}/>
                <Route exact path={routePaths.users.children.controllers.edit()} component={ControllerEdit}/>
                {/*Users - Administrators*/}
                <Route exact path={routePaths.users.children.administrators.index} component={Administrators}/>
                <Route exact path={routePaths.users.children.administrators.create} component={AdministratorCreate}/>
                <Route exact path={routePaths.users.children.administrators.edit()} component={AdministratorEdit}/>
                {/*Users - Super Administrators*/}
                <Route exact path={routePaths.users.children.super_administrators.index} component={SuperAdministrators}/>
                <Route exact path={routePaths.users.children.super_administrators.create} component={SuperAdministratorCreate}/>
                <Route exact path={routePaths.users.children.super_administrators.edit()} component={SuperAdministratorEdit}/>
                {/*Users - Analitic Operators*/}
                <Route exact path={routePaths.users.children.analitic_operators.index} component={AnaliticOperators}/>
                <Route exact path={routePaths.users.children.analitic_operators.create} component={AnaliticOperatorCreate}/>
                <Route exact path={routePaths.users.children.analitic_operators.edit()} component={AnaliticOperatorEdit}/>
                {/*Users - Operators*/}
                <Route exact path={routePaths.users.children.operators.index} component={Operators}/>
                <Route exact path={routePaths.users.children.operators.create} component={OperatorCreate}/>
                <Route exact path={routePaths.users.children.operators.edit()} component={OperatorEdit}/>
                {/*Users - Call Operators*/}
                <Route exact path={routePaths.users.children.call_operators.index} component={CallOperators}/>
                <Route exact path={routePaths.users.children.call_operators.create} component={CallOperatorCreate}/>
                <Route exact path={routePaths.users.children.call_operators.edit()} component={CallOperatorEdit}/>
                {/*Users - Sealing Specialists*/}
                <Route exact path={routePaths.users.children.sealing_specialists.index} component={SealingSpecialists}/>
                <Route exact path={routePaths.users.children.sealing_specialists.create} component={SealingSpecialistCreate}/>
                <Route exact path={routePaths.users.children.sealing_specialists.edit()} component={SealingSpecialistEdit}/>
                {/*Users - Sealing Operators*/}
                <Route exact path={routePaths.users.children.sealer_operator.index} component={SealingOperators}/>
                <Route exact path={routePaths.users.children.sealer_operator.create} component={SealingOperatorCreate}/>
                <Route exact path={routePaths.users.children.sealer_operator.edit()} component={SealingOperatorEdit}/>
                {/*Users - Service Organizations Operators*/}
                <Route exact path={routePaths.users.children.service_organizations_operators.index} component={ServiceOrganizationsOperators}/>
                <Route exact path={routePaths.users.children.service_organizations_operators.create} component={ServiceOrganizationsOperatorsCreate}/>
                <Route exact path={routePaths.users.children.service_organizations_operators.edit()} component={ServiceOrganizationsOperatorsEdit}/>
                {/*Users - Service Organizations Operators*/}
                <Route exact path={routePaths.users.children.service_organizations_sealers.index} component={ServiceOrganizationsSealers}/>
                <Route exact path={routePaths.users.children.service_organizations_sealers.create} component={ServiceOrganizationsSealersCreate}/>
                <Route exact path={routePaths.users.children.service_organizations_sealers.edit()} component={ServiceOrganizationsSealersEdit}/>
                {/*Laboratories*/}
                <Route exact path={routePaths.laboratories.index} component={Laboratories}/>
                <Route exact path={routePaths.laboratories.create} component={LaboratoryCreate}/>
                <Route path={routePaths.laboratories.edit.index()} component={LaboratoryEdit}/>
                {/*Companies*/}
                <Route exact path={routePaths.companies.index} component={Companies}/>
                <Route exact path={routePaths.companies.create} component={CompanyCreate}/>
                <Route path={routePaths.companies.edit.index()} component={CompanyEdit}/>
                {/*Service Organizations*/}
                <Route exact path={routePaths.service_organizations.index} component={ServiceOrganizations}/>
                <Route exact path={routePaths.service_organizations.create} component={ServiceOrganizationsCreate}/>
                <Route path={routePaths.service_organizations.edit.index()} component={ServiceOrganizationsEdit}/>
                {/*Sectors*/}
                <Route exact path={routePaths.sectors.index} component={Sectors}/>
                <Route exact path={routePaths.sectors.create} component={SectorCreate}/>
                <Route path={routePaths.sectors.edit.index()} component={SectorEdit}/>
                {/*Addresses*/}
                <Redirect exact from={routePaths.addresses.index} to={routePaths.addresses.children.regions.index}/>
                {/*Addresses - Regions*/}
                <Route exact path={routePaths.addresses.children.regions.index} component={Regions}/>
                <Route exact path={routePaths.addresses.children.regions.create} component={RegionCreate}/>
                <Route path={routePaths.addresses.children.regions.edit.index()} component={RegionEdit}/>
                {/*Addresses - Cities*/}
                <Route exact path={routePaths.addresses.children.cities.index} component={Cities}/>
                <Route exact path={routePaths.addresses.children.cities.create} component={CityCreate}/>
                <Route path={routePaths.addresses.children.cities.edit.index()} component={CityEdit}/>
                {/*Addresses - Districts*/}
                <Route exact path={routePaths.addresses.children.districts.index} component={Districts}/>
                <Route exact path={routePaths.addresses.children.districts.create} component={DistrictCreate}/>
                <Route path={routePaths.addresses.children.districts.edit.index()} component={DistrictEdit}/>
                {/*Addresses - Microdistricts*/}
                <Route exact path={routePaths.addresses.children.microdistricts.index} component={Microdistricts}/>
                <Route exact path={routePaths.addresses.children.microdistricts.create} component={MicrodistrictCreate}/>
                <Route path={routePaths.addresses.children.microdistricts.edit.index()} component={MicrodistrictEdit}/>
                {/*Addresses - Streets*/}
                <Route exact path={routePaths.addresses.children.streets.index} component={Streets}/>
                <Route exact path={routePaths.addresses.children.streets.create} component={StreetCreate}/>
                <Route path={routePaths.addresses.children.streets.edit.index()} component={StreetEdit}/>
                {/*Addresses - Houses*/}
                <Route exact path={routePaths.addresses.children.houses.index} component={Houses}/>
                <Route exact path={routePaths.addresses.children.houses.create} component={HouseCreate}/>
                <Route path={routePaths.addresses.children.houses.edit.index()} component={HouseEdit}/>
                {/*Addresses - Apartments*/}
                <Route exact path={routePaths.addresses.children.apartments.index} component={Apartments}/>
                <Route exact path={routePaths.addresses.children.apartments.create} component={ApartmentCreate}/>
                <Route path={routePaths.addresses.children.apartments.edit.index()} component={ApartmentEdit}/>
                {/*Bankbooks*/}
                <Route exact path={routePaths.bankbooks.index} component={Bankbooks}/>
                <Route exact path={routePaths.bankbooks.create} component={BankbookCreate}/>
                <Route path={routePaths.bankbooks.edit.index()} component={BankbookEdit}/>
                {/*Devices*/}
                <Route exact path={routePaths.devices.index} component={Devices}/>
                <Route exact path={routePaths.devices.create} component={DeviceCreate}/>
                <Route path={routePaths.devices.edit.index()} component={DeviceEdit}/>
                {/*Indications*/}
                <Redirect exact from={routePaths.indications.index} to={routePaths.indications.children.on_check.index}/>
                <Route exact path={routePaths.indications.children.all.index}>
                    <Indications statusText={t('sidebar.indications.children.all')}/>
                </Route>
                <Route exact path={routePaths.indications.children.on_check.index}>
                    <Indications key={routePaths.indications.children.on_check.index} statusId="0"
                                 statusText={t('sidebar.indications.children.on_check')}/>
                </Route>
                <Route exact path={routePaths.indications.children.approved.index}>
                    <Indications key={routePaths.indications.children.approved.index} statusId="1"
                                 statusText={t('sidebar.indications.children.approved')}/>
                </Route>
                <Route exact path={routePaths.indications.children.declined.index}>
                    <Indications key={routePaths.indications.children.declined.index} statusId="2"
                                 statusText={t('sidebar.indications.children.declined')}/>
                </Route>
                <Route exact path={routePaths.indications.create} component={IndicationCreate}/>
                <Route path={routePaths.indications.edit.index()} component={IndicationEdit}/>
                {/*Dictionaries*/}
                <Redirect exact from={routePaths.dictionaries.index}
                          to={routePaths.dictionaries.children.resource_types.index}/>
                {/*Dictionaries - Resource types*/}
                <Route exact path={routePaths.dictionaries.children.resource_types.index}>
                    <Dictionaries
                        route={routePaths.dictionaries.children.resource_types}
                        service={DictionariesServices.resourceTypes}
                        title={t('sidebar.dictionaries.children.resource_types')}
                    />
                </Route>
                <Route exact path={routePaths.dictionaries.children.resource_types.create}>
                    <DictionaryCreate
                        route={routePaths.dictionaries.children.resource_types}
                        service={DictionariesServices.resourceTypes}
                        description={true}
                        avatar={true}
                        title={t('sidebar.dictionaries.children.resource_types')}
                    />
                </Route>
                <Route exact path={routePaths.dictionaries.children.resource_types.edit()}>
                    <DictionaryEdit
                        route={routePaths.dictionaries.children.resource_types}
                        service={DictionariesServices.resourceTypes}
                        description={true}
                        avatar={true}
                        title={t('sidebar.dictionaries.children.resource_types')}
                    />
                </Route>
                {/*Dictionaries - Beautification*/}
                <Route exact path={routePaths.dictionaries.children.improvements.index}>
                    <Dictionaries
                        route={routePaths.dictionaries.children.improvements}
                        service={DictionariesServices.improvements}
                        title={t('sidebar.dictionaries.children.improvements')}
                    />
                </Route>
                <Route exact path={routePaths.dictionaries.children.improvements.create}>
                    <DictionaryCreate
                        route={routePaths.dictionaries.children.improvements}
                        service={DictionariesServices.improvements}
                        description={true}
                        title={t('sidebar.dictionaries.children.improvements')}
                    />
                </Route>
                <Route exact path={routePaths.dictionaries.children.improvements.edit()}>
                    <DictionaryEdit
                        route={routePaths.dictionaries.children.improvements}
                        service={DictionariesServices.improvements}
                        description={true}
                        title={t('sidebar.dictionaries.children.improvements')}
                    />
                </Route>
                {/*Dictionaries - Service Types*/}
                <Route exact path={routePaths.dictionaries.children.service_types.index}>
                    <Dictionaries
                        route={routePaths.dictionaries.children.service_types}
                        service={DictionariesServices.services}
                        title={t('sidebar.dictionaries.children.service_types')}
                    />
                </Route>
                <Route exact path={routePaths.dictionaries.children.service_types.create}>
                    <ServiceTypeCreate/>
                </Route>
                <Route exact path={routePaths.dictionaries.children.service_types.edit()}>
                    <ServiceTypeEdit/>
                </Route>
                {/*Dictionaries - Application Reject Reason*/}
                <Route exact path={routePaths.dictionaries.children.application_reject_reason.index}>
                    <ApplicationRejectReason/>
                </Route>
                <Route exact path={routePaths.dictionaries.children.application_reject_reason.create}>
                    <ApplicationRejectReasonCreate/>
                </Route>
                <Route exact path={routePaths.dictionaries.children.application_reject_reason.edit()}>
                    <ApplicationRejectReasonEdit/>
                </Route>
                {/*Dictionaries - Sealing Reject Reason*/}
                <Route exact path={routePaths.dictionaries.children.sealing_reject_reason.index}>
                    <SealingRejectReason/>
                </Route>
                <Route exact path={routePaths.dictionaries.children.sealing_reject_reason.create}>
                    <SealingRejectReasonCreate/>
                </Route>
                <Route exact path={routePaths.dictionaries.children.sealing_reject_reason.edit()}>
                    <SealingRejectReasonEdit/>
                </Route>
                {/*Dictionaries - Device Reject Reason*/}
                <Route exact path={routePaths.dictionaries.children.device_reject_reason.index}>
                    <DeviceRejectReason/>
                </Route>
                <Route exact path={routePaths.dictionaries.children.device_reject_reason.create}>
                    <DeviceRejectReasonCreate/>
                </Route>
                <Route exact path={routePaths.dictionaries.children.device_reject_reason.edit()}>
                    <DeviceRejectReasonEdit/>
                </Route>
                {/*Applications*/}
                <Redirect exact from={routePaths.applications.index} to={routePaths.applications.children.all.index}/>
                <Route exact path={routePaths.applications.index} component={Applications}/>
                <Route exact path={routePaths.applications.children.all.index}>
                    <Applications key={routePaths.applications.children.all.index}
                                  statusText={t('sidebar.applications.children.all')}/>
                </Route>
                <Route exact path={routePaths.applications.children.under_consideration.index}>
                    <Applications key={routePaths.applications.children.under_consideration.index} statusId="1"
                                 statusText={t('sidebar.applications.children.under_consideration')}/>
                </Route>
                <Route exact path={routePaths.applications.children.rejected.index}>
                    <Applications key={routePaths.applications.children.rejected.index} statusId="2"
                                  statusText={t('sidebar.applications.children.rejected')}/>
                </Route>
                <Route exact path={routePaths.applications.children.awaiting_payment.index}>
                    <Applications key={routePaths.applications.children.awaiting_payment.index} statusId="3"
                                  statusText={t('sidebar.applications.children.awaiting_payment')}/>
                </Route>
                <Route exact path={routePaths.applications.children.awaiting_completion.index}>
                    <Applications key={routePaths.applications.children.awaiting_completion.index} statusId="4"
                                  statusText={t('sidebar.applications.children.awaiting_completion')}/>
                </Route>
                <Route exact path={routePaths.applications.children.in_work.index}>
                    <Applications key={routePaths.applications.children.in_work.index} statusId="5"
                                  statusText={t('sidebar.applications.children.in_work')}/>
                </Route>
                <Route exact path={routePaths.applications.children.cancelled.index}>
                    <Applications key={routePaths.applications.children.cancelled.index} statusId="6"
                                  statusText={t('sidebar.applications.children.cancelled')}/>
                </Route>
                <Route exact path={routePaths.applications.children.done.index}>
                    <Applications key={routePaths.applications.children.done.index} statusId="7"
                                  statusText={t('sidebar.applications.children.done')}/>
                </Route>
                <Route path={routePaths.applications.create} component={ApplicationCreate}/>
                <Route path={routePaths.applications.detail.index()} component={Application}/>
                {/*Invoices*/}
                <Route exact path={routePaths.invoices.index} component={Invoices}/>
                {/*Settings*/}
                <Redirect exact from={routePaths.settings.index} to={routePaths.settings.children.indication_settings_app.index}/>
                <Route exact path={routePaths.settings.children.indication_settings_app.index} component={IndicationsSettingsApp}/>
                {/*Notifications*/}
                <Route exact path={routePaths.notifications.index} component={Notifications}/>
                <Route exact path={routePaths.notifications.create} component={NotificationCreate}/>
                <Route exact path={routePaths.notifications.detail()} component={NotificationDetail}/>
                {/*Imports*/}
                <Redirect exact from={routePaths.data_import.index} to={routePaths.data_import.children.addresses.index}/>
                <Route exact path={routePaths.data_import.children.addresses.index} component={AddressesImport}/>
                <Route exact path={routePaths.data_import.children.devices.index} component={DevicesImport}/>
                <Route exact path={routePaths.data_import.children.indications.index} component={IndicationsImport}/>
                <Route exact path={routePaths.data_import.children.devices_to_delete.index} component={DevicesDeleteImport}/>
                {/*Tasks Companies*/}
                <Route exact path={routePaths.tasks.children.companies.index} component={TasksCompanies}/>
                <Route exact path={routePaths.tasks.children.companies.edit()} component={TaskComponiesEdit}/>

                {/*Tasks Controllers*/}
                <Route exact path={routePaths.tasks.children.controllers.index} component={TaskController}/>
                <Route exact path={routePaths.tasks.children.controllers.edit()} component={TaskControllerEdit}/>            
                
                {/*Tasks Sealers*/}
                <Route exact path={routePaths.tasks.children.sealers.index} component={TaskSearlers}/>
                <Route exact path={routePaths.tasks.children.sealers.edit()} component={TaskSealersEdit}/>     

                {/*Tasks Statistics*/}
                <Route exact path={routePaths.tasks.children.statistic.children.controllers.index}>
                    <TasksStatics key={routePaths.tasks.children.statistic.children.controllers.index} role="controller"
                                 statusText={t('sidebar.tasks.children.statistics.children.controllers')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.statistic.children.sealers.index}>
                    <TasksStatics key={routePaths.tasks.children.statistic.children.sealers.index} role="sealer"
                                 statusText={t('sidebar.tasks.children.statistics.children.sealers')}/>
                </Route>


                {/*Tasks Rrimary Filling */}
                <Route exact path={routePaths.tasks.children.tasks_primary_filling.children.all.index}>
                    <TasksPrimaryFilling statusText={t('sidebar.tasks.children.tasks_primary_filling.children.all')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.tasks_primary_filling.children.on_check.index}>
                    <TasksPrimaryFilling key={routePaths.tasks.children.tasks_primary_filling.children.on_check.index} statusId="6"
                                 statusText={t('sidebar.tasks.children.tasks_primary_filling.children.on_check')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.tasks_primary_filling.children.done.index}>
                    <TasksPrimaryFilling key={routePaths.tasks.children.tasks_primary_filling.children.done.index} statusId="7"
                                 statusText={t('sidebar.tasks.children.tasks_primary_filling.children.done')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.tasks_primary_filling.children.declined.index}>
                    <TasksPrimaryFilling key={routePaths.tasks.children.tasks_primary_filling.children.declined.index} statusId="8"
                                 statusText={t('sidebar.tasks.children.tasks_primary_filling.children.declined')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.tasks_primary_filling.children.in_work.index}>
                    <TasksPrimaryFilling key={routePaths.tasks.children.tasks_primary_filling.children.in_work.index} statusId="5"
                                 statusText={t('sidebar.tasks.children.tasks_primary_filling.children.in_work')}/>
                </Route>
                <Route path={routePaths.tasks.children.tasks_primary_filling.edit()} component={TasksPrimaryFillingEdit}/>

                {/*Tasks Filling */}
                <Route exact path={routePaths.tasks.children.filling_tasks.children.all.index}>
                    <FillingTasks statusText={t('sidebar.tasks.children.filling_tasks.children.all')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.filling_tasks.children.on_check.index}>
                    <FillingTasks key={routePaths.tasks.children.filling_tasks.children.on_check.index} statusId="6"
                                 statusText={t('sidebar.tasks.children.filling_tasks.children.on_check')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.filling_tasks.children.done.index}>
                    <FillingTasks key={routePaths.tasks.children.filling_tasks.children.done.index} statusId="7"
                                 statusText={t('sidebar.tasks.children.filling_tasks.children.done')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.filling_tasks.children.declined.index}>
                    <FillingTasks key={routePaths.tasks.children.filling_tasks.children.declined.index} statusId="8"
                                 statusText={t('sidebar.tasks.children.filling_tasks.children.declined')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.filling_tasks.children.in_work.index}>
                    <FillingTasks key={routePaths.tasks.children.filling_tasks.children.in_work.index} statusId="5"
                                 statusText={t('sidebar.tasks.children.filling_tasks.children.in_work')}/>
                </Route>
                <Route path={routePaths.tasks.children.filling_tasks.edit()} component={FillingTasksEdit}/>

                {/*Tasks For Unsealing */}
                <Route exact path={routePaths.tasks.children.tasks_for_unsealing.children.all.index}>
                    <TasksUnsealing statusText={t('sidebar.tasks.children.tasks_for_unsealing.children.all')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.tasks_for_unsealing.children.on_check.index}>
                    <TasksUnsealing key={routePaths.tasks.children.tasks_for_unsealing.children.on_check.index} statusId="6"
                                 statusText={t('sidebar.tasks.children.tasks_for_unsealing.children.on_check')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.tasks_for_unsealing.children.done.index}>
                    <TasksUnsealing key={routePaths.tasks.children.tasks_for_unsealing.children.done.index} statusId="7"
                                 statusText={t('sidebar.tasks.children.tasks_for_unsealing.children.done')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.tasks_for_unsealing.children.declined.index}>
                    <TasksUnsealing key={routePaths.tasks.children.tasks_for_unsealing.children.declined.index} statusId="8"
                                 statusText={t('sidebar.tasks.children.tasks_for_unsealing.children.declined')}/>
                </Route>
                <Route exact path={routePaths.tasks.children.tasks_for_unsealing.children.in_work.index}>
                    <TasksUnsealing key={routePaths.tasks.children.tasks_for_unsealing.children.in_work.index} statusId="5"
                                 statusText={t('sidebar.tasks.children.tasks_for_unsealing.children.in_work')}/>
                </Route>
                <Route path={routePaths.tasks.children.tasks_for_unsealing.edit()} component={TasksUnsealingEdit}/>


                {/*Services Tasks Rrimary Filling */}
                <Route exact path={routePaths.tasks_service_organizations.children.tasks_primary_filling.children.all.index}>
                    <TasksPrimaryFillingServices statusText={t('sidebar.tasks_service_organizations.children.tasks_primary_filling.children.all')}/>
                </Route>
                <Route exact path={routePaths.tasks_service_organizations.children.tasks_primary_filling.children.on_check.index}>
                    <TasksPrimaryFillingServices key={routePaths.tasks_service_organizations.children.tasks_primary_filling.children.on_check.index} statusId="6"
                                 statusText={t('sidebar.tasks_service_organizations.children.tasks_primary_filling.children.on_check')}/>
                </Route>
                <Route exact path={routePaths.tasks_service_organizations.children.tasks_primary_filling.children.done.index}>
                    <TasksPrimaryFillingServices key={routePaths.tasks_service_organizations.children.tasks_primary_filling.children.done.index} statusId="7"
                                 statusText={t('sidebar.tasks_service_organizations.children.tasks_primary_filling.children.done')}/>
                </Route>
                <Route exact path={routePaths.tasks_service_organizations.children.tasks_primary_filling.children.declined.index}>
                    <TasksPrimaryFillingServices key={routePaths.tasks_service_organizations.children.tasks_primary_filling.children.declined.index} statusId="8"
                                 statusText={t('sidebar.tasks_service_organizations.children.tasks_primary_filling.children.declined')}/>
                </Route>
                <Route exact path={routePaths.tasks_service_organizations.children.tasks_primary_filling.children.in_work.index}>
                    <TasksPrimaryFillingServices key={routePaths.tasks_service_organizations.children.tasks_primary_filling.children.in_work.index} statusId="5"
                                 statusText={t('sidebar.tasks_service_organizations.children.tasks_primary_filling.children.in_work')}/>
                </Route>
                <Route path={routePaths.tasks_service_organizations.children.tasks_primary_filling.edit()} component={ServicesTasksPrimaryFillingEdit}/>

                {/*Services Tasks Filling */}
                <Route exact path={routePaths.tasks_service_organizations.children.filling_tasks.children.all.index}>
                    <FillingTasksServices statusText={t('sidebar.tasks_service_organizations.children.filling_tasks.children.all')}/>
                </Route>
                <Route exact path={routePaths.tasks_service_organizations.children.filling_tasks.children.on_check.index}>
                    <FillingTasksServices key={routePaths.tasks_service_organizations.children.filling_tasks.children.on_check.index} statusId="6"
                                 statusText={t('sidebar.tasks_service_organizations.children.filling_tasks.children.on_check')}/>
                </Route>
                <Route exact path={routePaths.tasks_service_organizations.children.filling_tasks.children.done.index}>
                    <FillingTasksServices key={routePaths.tasks_service_organizations.children.filling_tasks.children.done.index} statusId="7"
                                 statusText={t('sidebar.tasks_service_organizations.children.filling_tasks.children.done')}/>
                </Route>
                <Route exact path={routePaths.tasks_service_organizations.children.filling_tasks.children.declined.index}>
                    <FillingTasksServices key={routePaths.tasks_service_organizations.children.filling_tasks.children.declined.index} statusId="8"
                                 statusText={t('sidebar.tasks_service_organizations.children.filling_tasks.children.declined')}/>
                </Route>
                <Route exact path={routePaths.tasks_service_organizations.children.filling_tasks.children.in_work.index}>
                    <FillingTasksServices key={routePaths.tasks_service_organizations.children.filling_tasks.children.in_work.index} statusId="5"
                                 statusText={t('sidebar.tasks_service_organizations.children.filling_tasks.children.in_work')}/>
                </Route>
                <Route path={routePaths.tasks_service_organizations.children.filling_tasks.edit()} component={FillingTasksEditServices}/>

                {/*Tasks For Unsealing */}
                <Route exact path={routePaths.tasks_service_organizations.children.tasks_for_unsealing.children.all.index}>
                    <TasksUnsealingServices statusText={t('sidebar.tasks_service_organizations.children.tasks_for_unsealing.children.all')}/>
                </Route>
                <Route exact path={routePaths.tasks_service_organizations.children.tasks_for_unsealing.children.on_check.index}>
                    <TasksUnsealingServices key={routePaths.tasks_service_organizations.children.tasks_for_unsealing.children.on_check.index} statusId="6"
                                 statusText={t('sidebar.tasks_service_organizations.children.tasks_for_unsealing.children.on_check')}/>
                </Route>
                <Route exact path={routePaths.tasks_service_organizations.children.tasks_for_unsealing.children.done.index}>
                    <TasksUnsealingServices key={routePaths.tasks_service_organizations.children.tasks_for_unsealing.children.done.index} statusId="7"
                                 statusText={t('sidebar.tasks_service_organizations.children.tasks_for_unsealing.children.done')}/>
                </Route>
                <Route exact path={routePaths.tasks_service_organizations.children.tasks_for_unsealing.children.declined.index}>
                    <TasksUnsealingServices key={routePaths.tasks_service_organizations.children.tasks_for_unsealing.children.declined.index} statusId="8"
                                 statusText={t('sidebar.tasks_service_organizations.children.tasks_for_unsealing.children.declined')}/>
                </Route>
                <Route exact path={routePaths.tasks_service_organizations.children.tasks_for_unsealing.children.in_work.index}>
                    <TasksUnsealingServices key={routePaths.tasks_service_organizations.children.tasks_for_unsealing.children.in_work.index} statusId="5"
                                 statusText={t('sidebar.tasks_service_organizations.children.tasks_for_unsealing.children.in_work')}/>
                </Route>
                <Route path={routePaths.tasks_service_organizations.children.tasks_for_unsealing.edit()} component={TasksUnsealingEditServices}/>

                {/*ModelChanges*/}
                <Route exact path={routePaths.model_сhanges.children.bankbooks.all.index}>
                    <ModelChangesBankbooks statusText={t('sidebar.indications.children.all')}/>
                </Route>
                <Route exact path={routePaths.model_сhanges.children.bankbooks.on_check.index}>
                    <ModelChangesBankbooks key={routePaths.model_сhanges.children.bankbooks.on_check.index} statusId="0"
                                 statusText={t('sidebar.indications.children.on_check')}/>
                </Route>
                <Route exact path={routePaths.model_сhanges.children.bankbooks.approved.index}>
                    <ModelChangesBankbooks key={routePaths.model_сhanges.children.bankbooks.approved.index} statusId="1"
                                 statusText={t('sidebar.indications.children.approved')}/>
                </Route>
                <Route exact path={routePaths.model_сhanges.children.bankbooks.declined.index}>
                    <ModelChangesBankbooks key={routePaths.model_сhanges.children.bankbooks.declined.index} statusId="2"
                                 statusText={t('sidebar.indications.children.declined')}/>
                </Route>
                <Route path={routePaths.model_сhanges.edit.index()} component={ModelChangesBankbookEdit}/>


                <Route exact path={routePaths.model_сhanges.children.devices.all.index}>
                    <ModelChangesDevices statusText={t('sidebar.indications.children.all')}/>
                </Route>
                <Route exact path={routePaths.model_сhanges.children.devices.on_check.index}>
                    <ModelChangesDevices key={routePaths.model_сhanges.children.devices.on_check.index} statusId="0"
                                 statusText={t('sidebar.indications.children.on_check')}/>
                </Route>
                <Route exact path={routePaths.model_сhanges.children.devices.approved.index}>
                    <ModelChangesDevices key={routePaths.model_сhanges.children.devices.approved.index} statusId="1"
                                 statusText={t('sidebar.indications.children.approved')}/>
                </Route>
                <Route exact path={routePaths.model_сhanges.children.devices.declined.index}>
                    <ModelChangesDevices key={routePaths.model_сhanges.children.devices.declined.index} statusId="2"
                                 statusText={t('sidebar.indications.children.declined')}/>
                </Route>

                <Route path={routePaths.model_сhanges.edit.devices()} component={ModelChangesDevicesEdit}/>
            </Switch>
        </DefaultLayout>
    );
};

export default Routes;