import http from "./http-common"
import {getData, postData, deleteData} from "../handlers";

export default class CompaniesServices {
    static view = () => {
        return getData(http, `/serviceOrganization`)
    }

    static update = (data) => {
        return postData(http, `/serviceOrganization`, data)
    }

    static delete = () => {
        return deleteData(http, `/serviceOrganization`)
    }

    static cities = {
        index: (queryString) => {
            return getData(http, `/serviceOrganization/cities${queryString ? `?${queryString}` : ""}`)
        },
        attach: (data) => {
            return postData(http, `/serviceOrganization/cities/attach`, data)
        },
        detach: (id) => {
            return postData(http, `/serviceOrganization/cities/detach`, {city_id: id})
        }
    }

    static operators = {
        index: (queryString) => {
            return getData(http, `/serviceOrganization/operators${queryString ? `?${queryString}` : ""}`)
        },
        attach: (data) => {
            return postData(http, `/serviceOrganization/operators/attach`, data)
        },
        detach: (id) => {
            return postData(http, `/serviceOrganization/operators/detach`, {user_id: id})
        }
    }

    static controllers = {
        index: (queryString) => {
            return getData(http, `/serviceOrganization/controllers${queryString ? `?${queryString}` : ""}`)
        },
        attach: (data) => {
            return postData(http, `/serviceOrganization/controllers/attach`, data)
        },
        detach: (id) => {
            return postData(http, `/serviceOrganization/controllers/detach`, {user_id: id})
        }
    }

    static sealers = {
        index: ( queryString) => {
            return getData(http, `/serviceOrganization/sealers${queryString ? `?${queryString}` : ""}`)
        },
        attach: (data) => {
            return postData(http, `/serviceOrganization/sealers/attach`, data)
        },
        detach: (id) => {
            return postData(http, `/serviceOrganization/sealers/detach`, {user_id: id})
        }
    }

    static sealer_operators = {
        index: (queryString) => {
            return getData(http, `/serviceOrganization/sealerOperators${queryString ? `?${queryString}` : ""}`)
        },
        attach: (data) => {
            return postData(http, `/serviceOrganization/sealerOperators/attach`, data)
        },
        detach: (id) => {
            return postData(http, `/serviceOrganization/sealerOperators/detach`, {user_id: id})
        }
    }

    static analytics = {
        index: (queryString) => {
            return getData(http, `/serviceOrganization/analytics${queryString ? `?${queryString}` : ""}`)
        },
        attach: (data) => {
            return postData(http, `/serviceOrganization/analytics/attach`, data)
        },
        detach: (id) => {
            return postData(http, `/serviceOrganization/analytics/detach`, {user_id: id})
        }
    }

    static sectors = {
        index: (queryString) => {
            return getData(http, `/serviceOrganization/sectors${queryString ? `?${queryString}` : ""}`)
        },
        attach: (data) => {
            return postData(http, `/serviceOrganization/sectors/attach`, data)
        },
        detach: (id) => {
            return postData(http, `/serviceOrganization/sectors/detach`, {sector_id: id})
        }
    }

    static addresses = {
        index: (queryString) => {
            return getData(http, `/serviceOrganization/addresses${queryString ? `?${queryString}` : ""}`)
        },
        attach: (data) => {
            return postData(http, `/serviceOrganization/addresses/attach`, data)
        },
        attachable: (queryString) => {
            return getData(http, `/serviceOrganization/addresses/attachable${queryString ? `?${queryString}` : ""}`)
        },
        detach: (id) => {
            return postData(http, `/serviceOrganization/addresses/detach`, {address_id: id})
        }
    }
}