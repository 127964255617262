import React, {useEffect} from 'react';
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {useForm, Controller} from "react-hook-form";
import routePaths from "../../../../navigation/serviceOrganizationOperator/routePaths";
import useStore from "../../../../hooks/useStore.hook";
import FormGroup from "../../../../components/FormGroup";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import WhiteBlock from "../../../../components/WhiteBlock";
import CustomAsyncSelect from "../../../../components/CustomAsyncSelect";
import Dictionaries from "../../../../services/serviceOrganizationOperator/dictionaries";
import {useLocation} from "react-router-dom";
import InputMessage from "../../../../components/InputMessage";
import ServiceOrganizationsSealersServices from '../../../../services/serviceOrganizationOperator/serviceOrganizationsSealersServices';

const ServiceOrganizationsSealersCreate = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const {handleSubmit, register, control, formState: {errors}} = useForm();

    const {state} = useLocation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.users.index,
                title: t('sidebar.users.title')
            },
            {
                path: routePaths.users.children.service_organizations_sealers.index,
                title: t('sidebar.users.children.service_organizations_sealers')
            },
            {
                path: null,
                title: t('labels.creating')
            }
        ])
    }, [setBreadcrumbs, t])

    const {onSubmit, loading} = useStore(ServiceOrganizationsSealersServices.store);

    return (
        <>
            <h1 className="headline-4">{t('labels.creating')}</h1>
            <WhiteBlock>
                <form onSubmit={handleSubmit(onSubmit)} className={loading ? "isLoading" : ""}>
                    <FormGroup label={t('labels.fullName') + " *"}>
                        <Input type="text" {...register('name', {required: true})} $error={errors.name}/>
                        {errors.name && errors.name.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.phone_number')}>
                        <Input type="tel" {...register('phone')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.email') + " *"}>
                        <Input type="text" {...register('email', {
                            required: true,
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: t('messages.invalid_email')
                            }
                        })} $error={errors.email}/>
                        {errors.email && errors.email.type === 'required' && <InputMessage $error>{t('messages.required')}</InputMessage>}
                        {errors.email && <InputMessage $error>{errors.email.message}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.service_organization') + " *"}>
                        <Controller
                            defaultValue={state ? String(state?.service_organization_id) : null}
                            name="service_organization_id"
                            control={control}
                            render={({field: {onChange, value}}) => (
                                <CustomAsyncSelect
                                    method={Dictionaries.serviceOrganizations}
                                    onChange={onChange}
                                    value={value}
                                    isDisabled={state?.service_organization_id}
                                    $error={errors.service_organization_id}
                                />
                            )}
                            rules={{required: true}}
                        />
                        {errors.service_organization_id && errors.service_organization_id.type === 'required' && <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <div className="text-right">
                        <Button>{t('labels.save')}</Button>
                    </div>
                </form>
            </WhiteBlock>
        </>
    );
};

export default ServiceOrganizationsSealersCreate;