import React, {useCallback, useEffect, useState} from 'react';
import FormGroup from "../../../../../components/FormGroup";
import {useTranslation} from "react-i18next";
import Utils from "../../../../../services/utils";
import {useForm, Controller} from "react-hook-form";
import Input from "../../../../../components/Input";
import Dictionaries from "../../../../../services/serviceOrganizationOperator/dictionaries";
import Button from "../../../../../components/Button";
import CustomSelect from "../../../../../components/CustomSelect";
import {toast} from "react-toastify";

const HousesFilter = ({setFilterData, params}) => {
    const {t} = useTranslation();
    const [isOpened, setIsOpened] = useState(false);


    const {handleSubmit, register, watch, setValue, control, reset} = useForm();

    const regionId = watch('region_id', null);
    const cityId = watch('city_id', null);
    const companyId = watch('company_id', null);
    const districtId = watch('district_id', null)
    const microdistrictId = watch('microdistrict_id', null)
    const streetId = watch('street_id', null)

    const [regions, setRegions] = useState();
    const [regionsLoading, setRegionsLoading] = useState(true);

    const [cities, setCities] = useState();
    const [citiesLoading, setCitiesLoading] = useState(true);

    const [districts, setDistricts] = useState();
    const [districtsLoading, setDistrictsLoading] = useState(true);

    const [companies, setCompanies] = useState();
    const [companiesLoading, setCompaniesLoading] = useState(true);

    const [microdistricts, setMicrodistricts] = useState();
    const [microdistrictsLoading, setMicrodistrictsLoading] = useState(true);

    const [streets, setStreets] = useState();
    const [streetsLoading, setStreetsLoading] = useState(true);

    const fetchCompanies = useCallback(async () => {
        setCompaniesLoading(true);

        const res = await Dictionaries.companies();

        if (res.statusCode === 200) {
            setCompanies(res.content);
            setValue('company_id', String(res.content[0].value))
        } else {
             toast.error(res.message)
        }

        setCompaniesLoading(false);
    }, [setValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchRegions = useCallback(async () => {
        setRegionsLoading(true);
        const res = await Dictionaries.regions();

        if (res.statusCode === 200) {
            setRegions(res.content);
            setValue('region_id', String(res.content[0].value))
        } else {
             toast.error(res.message)
        }
        setRegionsLoading(false);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchCities = useCallback(async () => {
        setCities(null)

        if (regionId) {
            setCitiesLoading(true);

            const res = await Dictionaries.cities({queryString: `region_id=${regionId}`});

            if (res.statusCode === 200) {
                setCities(res.content);

                if (!res.content.some(item => item.value === cityId)) {
                    setValue('city_id', null)
                }

                setValue('city_id', String(res.content[0].value))
            } else {
                toast.error(res.message)
            }

            setCitiesLoading(false);
        } else {
            setValue('city_id', null)
        }
    }, [setValue, regionId]);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchDistricts = useCallback(async () => {
        if (cityId) {
            setDistrictsLoading(true);

            const res = await Dictionaries.districts({queryString: `city_id=${cityId}`});

            if (res.statusCode === 200) {
                setDistricts(res.content);

                if (!res.content.some(item => item.value === districtId)) {
                    setValue('district_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setDistrictsLoading(false);
        } else {
            setValue('district_id', null)
        }
    }, [setValue, cityId]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchMicrodistricts = useCallback(async () => {
        if (districtId) {
            setMicrodistrictsLoading(true);

            const res = await Dictionaries.microdistricts({queryString: `district_id=${districtId}`});

            if (res.statusCode === 200) {
                setMicrodistricts(res.content);

                if (!res.content.some(item => item.value === microdistrictId)) {
                    setValue('microdistrict_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setMicrodistrictsLoading(false);
        } else {
            setValue('microdistrict_id', null)
        }
    }, [setValue, districtId]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchStreets = useCallback(async () => {
        if (districtId) {
            setStreetsLoading(true);

            const microdistrictQuery = microdistrictId ? `&microdistrict_id=${microdistrictId}` : "";

            const res = await Dictionaries.streets({queryString: `district_id=${districtId}` + microdistrictQuery});

            if (res.statusCode === 200) {
                setStreets(res.content);

                if (!res.content.some(item => item.value === streetId)) {
                    setValue('street_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setStreetsLoading(false);
        } else {
            setValue('street_id', null)
        }
    }, [setValue, districtId, microdistrictId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchRegions();
    }, [fetchRegions]);

    useEffect(() => {
        fetchCities();
    }, [fetchCities]);

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    useEffect(() => {
        fetchDistricts();
    }, [fetchDistricts]);

    useEffect(() => {
        fetchMicrodistricts();
    }, [fetchMicrodistricts]);

    useEffect(() => {
        fetchStreets();
    }, [fetchStreets]);

    const onSubmit = (data) => {
        setFilterData(Utils.objectToSearchParams(data))
    };

    const handleReset = (e) => {
        e.preventDefault();
        reset({
            name: null,
            company_id: null,
            region_id: null,
            city_id: null,
            district_id: null,
            street_id: null,
        });
        setFilterData(Utils.objectToSearchParams({
            name: '',
            company_id: '',
            region_id: '',
            city_id: '',
            district_id: '',
            street_id: '',
        }))
    }

    const open = () => {
        setIsOpened(!isOpened)
    }

    return (
        <div className="filter-container mb-20">
            <h3 onClick={open}>
                <span>Фильтр</span>
                <i className={isOpened ? "icon-chevron-up" : "icon-chevron-down"}></i>
            </h3>
            {isOpened &&
            <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%", marginTop:"20px"}}>
                <div className="filter-wrapper">
                    <FormGroup label={t('labels.name')}>
                        <Input $small defaultValue={params.get('name')} type="text" {...register('name')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.resource_company')}>
                        <Controller name="company_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={companies} onChange={onChange}
                                                    value={value}
                                                    isLoading={companiesLoading}
                                                    $small
                                        />
                                    )}
                        />
                    </FormGroup>
                    <FormGroup label={t('labels.region')}>
                        <Controller name="region_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={regions} onChange={onChange}
                                                    value={value}
                                                    isLoading={regionsLoading}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.city')}>
                        <Controller name="city_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={cities} onChange={onChange}
                                                    value={value}
                                                    isLoading={(!!regionId) ? citiesLoading : false}
                                                    isDisabled={!!!regionId || citiesLoading}
                                                    $small
                                        />
                                    )}
                        />
                    </FormGroup>
                    <FormGroup label={t('labels.district')}>
                        <Controller name="district_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={districts} onChange={onChange}
                                                    value={value}
                                                    isLoading={!!cityId ? districtsLoading : false} isDisabled={!!!cityId || districtsLoading}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.microdistrict')}>
                        <Controller name="microdistrict_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={microdistricts} onChange={onChange}
                                                    value={value}
                                                    isLoading={!!districtId ? microdistrictsLoading : false} isDisabled={!!!districtId || microdistrictsLoading}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.street')}>
                        <Controller name="street_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={streets} onChange={onChange}
                                                    value={value}
                                                    isLoading={!!districtId ? streetsLoading : false} isDisabled={!!!districtId || streetsLoading}/>
                                    )}/>
                    </FormGroup>
                    </div>
                <div className="row row--multiline justify-end">
                    <div className="col-auto">
                        <Button  $small $fluid>{t('labels.apply')}</Button>
                    </div>
                    <div className="col-auto">
                        <Button onClick={handleReset} variant="outline" $small $fluid>{t('labels.reset')}</Button>
                    </div>
                </div>
            </form>
        }
        </div>
    );
};

export default HousesFilter;
