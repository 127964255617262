import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../../navigation/serviceOrganizationOperator/routePaths";
import useFetchItems from "../../../../hooks/useFetchItems";
import AddressesServices from "../../../../services/serviceOrganizationOperator/addressesServices";
import HeadingWithButtons from "../../../../components/HeadingWithButtons";
import Button from "../../../../components/Button";
import WhiteBlock from "../../../../components/WhiteBlock";
import ItemsTable from "../../../../components/ItemsTable";
import Pagination from "../../../../components/Pagination";
import TablePreloader from "../../../../components/TablePreloader";
import DistrictsFilter from "./components/DistrictsFilter";

const Districts = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: routePaths.addresses.index,
                title: t('sidebar.addresses.title')
            },
            {
                path: null,
                title: t('sidebar.addresses.children.districts')
            }
        ])
    }, [setBreadcrumbs, t])

    const {items, loading, pageCount, params, handlePageClick, fetchItems, setFilterData} = useFetchItems(AddressesServices.districts.index)

    const [formattedItems, setFormattedItems] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, name, path, company}) => {
                return {
                    id,
                    fields: [
                        id,
                        name,
                        path,
                        company?.name
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.district'), t('labels.city'), t('labels.resource_company'), ''],
        sizes: ['10%', '30%', '30%', '30%', '']
    }

    return (
        <>
            <HeadingWithButtons>
                <h1 className="headline-4">{t('sidebar.addresses.children.districts')}</h1>
            </HeadingWithButtons>
            <DistrictsFilter setFilterData={setFilterData} params={params}/>
            <WhiteBlock>
                        {formattedItems
                            ? <>
                                <ItemsTable
                                    {...{config: tableConfig, fetchItems, loading}}
                                    items={formattedItems}
                                    editLink={routePaths.addresses.children.districts.edit.information}
                                    /*removeMethod={AddressesServices.districts.delete}*/
                                />
                                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                            </>
                            : <TablePreloader/>}
            </WhiteBlock>
        </>
    );
};

export default Districts;