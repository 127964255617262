import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../../navigation/serviceOrganizationOperator/routePaths";
import useFetchItems from "../../../../hooks/useFetchItems";
import HeadingWithButtons from "../../../../components/HeadingWithButtons";
import WhiteBlock from "../../../../components/WhiteBlock";
import ItemsTable from "../../../../components/ItemsTable";
import Pagination from "../../../../components/Pagination";
import TablePreloader from "../../../../components/TablePreloader";
import Expand from "../../../../components/Expand";
import StatusLabel from '../../../../components/StatusLabel';
import { statusesColors } from '../../../../constants';
import TaskSealingServices from '../../../../services/serviceOrganizationOperator/tasksSealingServices';
import FillingTasksFilter from './FillingTasksFilter';

const FillingTasks = ({statusId = "", statusText}) => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    useEffect(() => {
        setBreadcrumbs([
            {
                path: null,
                title: t('labels.tasks_filling')
            },
            statusText && {
                path: null,
                title: statusText
            }
        ])
    }, [setBreadcrumbs, t]) // eslint-disable-line react-hooks/exhaustive-deps

    const {items, loading, pageCount, params, handlePageClick, fetchItems, setFilterData} = useFetchItems(TaskSealingServices.sealing.index, statusId)
    
    const [formattedItems, setFormattedItems] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, company, address, bankbook, status, users}) => {
                return {
                    id,
                    fields: [
                        id,
                        company?.name,
                        <Expand height={50}>{address}</Expand>,
                        bankbook?.name,
                        <Expand height={51}>
                            {users.map(({name}) => name).join(', ')}
                        </Expand>,
                        <StatusLabel style={{wordBreak: 'break-word'}}
                                     color={statusesColors[status.id]}>{status.name}</StatusLabel>
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.resource_company'),  t('labels.object'), t('labels.bankbook'), t('labels.sealers'),t('labels.status'), ''],
        sizes: ['5%', '20%', '20%', '20%', '20%','10%', '']
    }

    return (
        <>
            <HeadingWithButtons>
                <h1 className="headline-4">{t('labels.tasks_filling') + ' - ' + statusText}</h1>
            </HeadingWithButtons>
            <FillingTasksFilter setFilterData={setFilterData} params={params}/>
            <WhiteBlock>
                        {formattedItems
                            ? <>
                                <ItemsTable
                                    {...{config: tableConfig, fetchItems, loading}}
                                    items={formattedItems}
                                    editLink={routePaths.tasks.children.filling_tasks.edit}
                                    removeMethod={TaskSealingServices.sealing.delete}
                                    vAlign={"top"}
                                />
                                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                            </>
                            : <TablePreloader/>}
            </WhiteBlock>
        </>
    );
};

export default FillingTasks;